import React from "react";
import ".././Home/Home.css";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";

var colors = ["#000000", "#ffffff", "#e60000", "#ff9900"];

var background = ["#000000", "#ffffff", "#facccc", "#ffebcc"];
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ color: colors }, { background: background }],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};
export class EditModalSingleLine extends React.PureComponent {
  positions = [];
  state = {
    show: true,
    text: this.props.defaultValue ? this.props.defaultValue : "",
  };
  componentDidUpdate(prevProps, prevState) {}
  handleSubmit = (e) => {
    if (this.state.text && this.state.text !== "")
      this.props.submitValue(this.state.text);
    else this.props.showAlert("Field cannot be empty");
  };
  handleClose = () => {};
  render() {
    return (
      <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
              {this.props.text && this.props.text !== ""
                ? 'this.props.text'
                : "Edit your text"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.isDescription ? (
              <ReactQuill
                modules={modules}
                style={{
                  minHeight: 160,
                  height: 160,
                  maxHeight: 160,
                  width: "100%",
                }}
                className='ql-container ql-height '
                value={this.state.text}
                defaultValue={this.props.defaultValue}
                onChange={(e) => {
                  this.setState({ text: e });
                }}
              />
            ) : (
              <textarea
                className='form-control'
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: 12,
                }}
                rows={5}
                defaultValue={this.props.defaultValue}
                onChange={(e) => {
                  // this.text = e.target.value;
                  this.setState({ text: e.target.value });
                }}
              ></textarea>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className='edit-modal-save-button'
              onClick={this.props.onCancelEditModal}
            >
              Close
            </button>
            <button
              className='edit-modal-save-button'
              onClick={this.handleSubmit}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default EditModalSingleLine;
