/* eslint-disable no-redeclare */ /* eslint-disable eqeqeq */
import React, { Component, Suspense } from "react";
import ReactTable from "react-table";
import copyIcon from "../assets/images/CopyClipboard.png";
import FittedImage from "react-fitted-image";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table/react-table.css";
import Popup from "reactjs-popup";
import axios from "axios";
import Pagination from "./Pagination";
import {
	IsTextHTML,
	baseURL,
	calculateZoneSpecificTime,
	secretUserLeadFilter,
} from "../Helpers/Constants";
import spamInactive from "../assets/images/spam-inactive.svg";
import spamActive from "../assets/images/spam-active.svg";
import { confirmAlert } from "react-confirm-alert"; // Import
import EditableTextView from "../Leads/EditableTextView";
import NotesTextView from "../Leads/NotesTextView";
import call from "../assets/images/call2.png";

import "../Home/Home.css";
import { debounce } from "lodash";
import emailIcon from "../assets/images/sms2.png";
import starIcon from "../assets/images/hotlist-star.png";
import unstarIcon from "../assets/images/hotlist-star-selected.png";
import smsIcon from "../assets/images/phone2.png";
import sentCheckIcon from "../assets/images/sequence-tickcircle.png";
import autoEmailsentCheckIcon from "../assets/images/orange_tick.png";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import FollowUpModal from "../ReusabelComponents/FollowUpModal";
import linkifyHtml from "linkify-html";
import { StartSequenceModal } from "../ReusabelComponents/Modals/Hotlist/StartSequence/StartSequencesModal";
import { PauseSequencDetailsModal } from "../ReusabelComponents/Modals/Hotlist/SequenceDetails2/PauseSequencDetailsModal";
import { SequenceConfirmation } from "../ReusabelComponents/Modals/Confirmation/SequenceConfirmation";
import {
	removeSequence,
	startPauseSequence,
	updateLeadSpamStatus,
} from "../../api/api";
import { ReminderModal } from "../ReusabelComponents/Modals/Reminder/reminder";
import NewAssigneeDropdown from "../Leads/AssigneeDropdownNew";
import MultipleEmailSms from "./MultipleEmailSms";
import FollowUpModalNew from "../ReusabelComponents/FollowUpModalNew";
var dateFormat = require("dateformat");
const ReactTableDraggableColumns = withDraggableColumns(ReactTable);
const arrayMove = require("array-move");
const CancelToken = axios.CancelToken;
const copy = require("clipboard-copy");
let source = CancelToken.source();

class LeadsScreen extends Component {
	selectionStrings =
		"lead_number utc_time_stamp name  email address phone_number svc_requested assigned_to bid_status service_fee lead_status is_complete archived actions starred_by_user_ids urgency email_auto_contact_count auto_contact_count auto_review_count auto_referral_count  harvestSMSSent source notes extra_links automatic_email_sent updated_at channel harvestEstimateID time_line sold_status email_selected_templates sms_selected_templates temp_color reminder isLeadSpam";
	myMessage = null;
	emailAttachment = null;
	test_receiver = null;
	pageSize = 30;
	filteredValue = "";

	selectedAssignee = localStorage.getItem("username"); //"All";
	optionsBidStatus = [
		{ value: "Draft", label: "Draft" },
		{ value: "Waiting", label: "Waiting" },
		{ value: "Rejected", label: "Rejected" },
		{ value: "Sent", label: "Sent" },
	];
	optionsLeadStatus = [
		{ value: "Pending", label: "Pending" },
		{ value: "In Progress", label: "In Progress" },
		{ value: "Completed", label: "Completed" },
	];
	optionsServiceFee = [
		{ value: "99", label: "99" },
		{ value: "125", label: "125" },
	];
	state = {
		pageSize: 30,
		selectedRowIndex: -1,
		filteredData: [],
		isLoading: false,
		timeCompleted: true,
		TotalNumberOfPages: 0,
		isFiltered: false,
		pageNum: 0,
		showSnoozeModal: false,
		searchInProgress: false,
		autoFollowUpMessage: "my messasge",
		sortedLeadData: this.props.leads,
		notesLead: null,
		tempLeadsData: null,
		showStartSequenceModal: false,
		showSequenceDetailsModal: false,
		showPauseSequenceModal: false,
		showPauseSequenceDetailsModal: false,
		showPauseSequenceModalSms: false,
		unPauseSequenceModal: false,
		showUpdateDatePopup: false,
		rowId: null,
		email: true,
		sequenceId: null,
		totalTemplates: null,
		startDate: null,
		leadId: null,
		pause: false,
		unPauseId: null,
		showPauseButton: true,
		selectedSequence: {},
		firstTemplateDate: null,
		secondTemplateDate: null,
		thirdTemplateDate: null,
		//==== Reminder ======
		reminderModal: false,
		updateReminder: false,
		leadName: "",
		reminderId: "",
		reminderDate: "",
		reminderDescription: "",
		selectedEmails: [],
		selectAll: false,
		multiEmailSmsTitle: "",
		pageIndex: 0,
	};
	//table
	//ma
	firstColumns = [
		{
			Header: "Select",
			accessor: "email",
			width: 50,
			maxWidth: 60,
			minWidth: 40,
			// filterable: false,

			Filter: () => (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<input
						className='checkbox-input1'
						style={{
							transform: "scale(1.5)",
							height: 80,
						}}
						type='checkbox'
						checked={this.state.selectAll}
						onChange={this.handleSelectAll}
					/>
				</div>
			),

			Cell: ({ row }) => (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}
				>
					<input
						style={{
							transform: "scale(1.5)",
						}}
						type='checkbox'
						checked={this.state.selectedEmails?.some(
							(item) => item.email === row.email && item.name === row.name
						)}
						// checked={this.state.selectedEmails.includes(row.email)}
						onChange={(e) => {
							this.handleCheckboxChange(
								e,
								row.name,
								row.email,
								row._original._id
							);
						}}
					/>
				</div>
			),
		},

		{
			Header: "Name",
			accessor: "name",
			width: 180,
			maxWidth: 180,
			minWidth: 80,
			// minResizeWidth: 80,

			Cell: (row) => (
				<EditableTextView
					selectedColumn={this.props.selectedCol}
					showAlert={this.props.showAlert}
					row={row}
					columnName='name'
					handleSubmit={this.handleSubmit}
					editableClassName='editableTwoLine'
					isClickable={false}
					isEditable={true}
					updateDataByID={this.props.updateDataByID}
					allowToUpdate={this.props.allowToUpdate}
					selectedTabIndex={this.props.selectedCol}
				/>
			),
			filterMethod: (filter, row) => {
				return row[filter.id]
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchName: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
						//   flag = !flag;
						//
					}}
				/>
			),
		},

		{
			Header: "Email",
			accessor: "email",
			width: 470,
			maxWidth: 470,
			minWidth: 100,
			// minResizeWidth: 100,

			filterMethod: (filter, row) => {
				return row[filter.id]
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchEmail: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						width: "100%",
						justifyContent: "space-between",
					}}
				>
					<Popup
						trigger={
							<div
								style={{
									marginBottom: 8,
									marginRight: 2,
									marginLeft: 7,
									width: 15,
									height: 15,
									position: "relative",
								}}
							>
								<img
									alt=''
									onClick={() =>
										this.contactLead(
											row.original._id,
											"email",
											row.original.assigned_to,
											row.index
										)
									}
									style={{
										cursor: "pointer",
										width: 21,
										height: 21,
										marginRight: 5,
									}}
									src={emailIcon}
								/>
								{row.original.email_auto_contact_count > 0 ? (
									<img
										alt=''
										style={{
											width: 12,
											height: 12,
											top: -3,
											right: -5,
											position: "absolute",
										}}
										src={sentCheckIcon}
									/>
								) : null}
								{row.original.automatic_email_sent != null &&
								row.original.automatic_email_sent ? (
									<img
										alt=''
										style={{
											width: 12,
											height: 12,
											top: -4,
											left: -2,
											position: "absolute",
										}}
										src={autoEmailsentCheckIcon}
									/>
								) : null}
							</div>
						}
						on={"hover"}
						position='top left'
					>
						{row.original.email_auto_contact_count > 0
							? `Email already sent.`
							: `Send canned email.`}
					</Popup>
					<div
						className='email-hotlist-mhr'
						style={{
							display: "flex",
							flex: 1,
							paddingLeft: 10,
						}}
					>
						<EditableTextView
							showAlert={this.props.showAlert}
							row={row}
							columnName='email'
							handleSubmit={this.handleSubmit}
							contactLead={this.contactLead}
							editableClassName='editableOneLine'
							urlPrefix='mailto:'
							isClickable={true}
						/>
					</div>
					<Popup
						trigger={
							row.original.fakeEmailWarning ? (
								<img
									src={require("../assets/images/invalidEmail.png")}
									className='invalidEmailIcon'
								/>
							) : null
							// <img
							// 	src={require("../assets/images/tick_mark.png")}
							// 	className="invalidEmailIcon "
							// />
						}
						on={"hover"}
						position='top left'
						arrow={false}
					>
						{row.original.fakeEmailWarning
							? "Warning: This email may not be valid"
							: "This email is valid"}
					</Popup>
					{/* sequence feature commented */}
					{/* {!row.original.sequenceStatus ||
					row.original.sequenceStatus === "0" ? (
						<div
							className="mhr-seqeunce-container "
							onClick={() => {
								this.setState({
									showStartSequenceModal: true,
									rowId: row.original._id,
									email: true,
								});
							}}
						>
							<img
								src={require("../assets/images/sequence-play.png")}
							/>
							<p>Sequence</p>
						</div>
					) : row.original.sequenceStatus === "2" ? (
						<div
							className="mhr-seqeunce-container mhr-seqeunce-container-yellow "
							onClick={async () => {
								const currentDate = new Date();
								// const date = await formatDate(row.original.nextTemplateDate);
								const passed = await hasDatePassed(
									// date,
									row.original.nextTemplateDate,
									currentDate.toLocaleDateString("en-US")
								);
								if (passed) {
									this.setState({
										unPauseId:
											row.original.assignedSequence,
										showUpdateDatePopup: true,
										pause: false,
										email: true,
										rowId: row.original._id,
									});
								} else {
									this.setState({
										showPauseSequenceModal: true,
										pause: false,
										email: true,
										leadId: row.original._id,
									});
								}
							}}
						>
							<img
								src={require("../assets/images/sequence-paused.png")}
							/>
							<p>Sequence</p>
						</div>
					) : row.original.nextTemplateNumber ===
							row.original.totalTemplates + 1 ||
					  row.original.sequenceStatus === "3" ? (
						<div
							className="mhr-seqeunce-container mhr-seqeunce-container-green"
							onClick={() => {
								this.props.showAlert(
									"Sequence already completed"
								);
								this.setState({
									showPauseSequenceDetailsModal: true,
									email: true,
									showPauseButton: false,
									pause: true,
									sequenceId: row.original.assignedSequence,
									totalTemplates: row.original.totalTemplates,
									startDate: row.original.startTemplateDate,
									nextTemplateNumber:
										row.original.nextTemplateNumber,
									firstTemplateDate:
										row.original.firstTemplateDate,
									secondTemplateDate:
										row.original.secondTemplateDate,
									thirdTemplateDate:
										row.original.thirdTemplateDate,
									leadId: row.original._id,
									selectedSequence:
										row.original.emailSequence,
								});
							}}
						>
							<p>Sequence</p>
							<img
								src={require("../assets/images/sequence-tickcircle-green.png")}
							/>
						</div>
					) : (
						<div className="mhr-seqeunce-container-played">
							<img
								className="mhr-seqeunce-container-played-img"
								src={require("../assets/images/sequence-pause.png")}
								onClick={() => {
									this.setState({
										showPauseSequenceModal: true,
										pause: true,
										email: true,
										leadId: row.original._id,
									});
								}}
							/>
							<div
								onClick={() => {
									this.setState({
										showPauseSequenceDetailsModal: true,
										email: true,
										pause: true,
										showPauseButton: true,
										sequenceId:
											row.original.assignedSequence,
										totalTemplates:
											row.original.totalTemplates,
										startDate:
											row.original.startTemplateDate,
										nextTemplateNumber:
											row.original.nextTemplateNumber,
										firstTemplateDate:
											row.original.firstTemplateDate,
										secondTemplateDate:
											row.original.secondTemplateDate,
										thirdTemplateDate:
											row.original.thirdTemplateDate,
										leadId: row.original._id,
										selectedSequence:
											row.original.emailSequence,
									});
								}}
							>
								{row.original.nextTemplateNumber > 1 &&
								row.original.totalTemplates > 0 ? (
									<img
										className="mhr-sequence-checked-image"
										src={require("../assets/images/sequence-tickcircle.png")}
									/>
								) : (
									row.original.totalTemplates > 0 && (
										<div>
											<p>1</p>
										</div>
									)
								)}
								{row.original.nextTemplateNumber > 2 &&
								row.original.totalTemplates > 1 ? (
									<img
										className="mhr-sequence-checked-image"
										src={require("../assets/images/sequence-tickcircle.png")}
									/>
								) : (
									row.original.totalTemplates > 1 && (
										<div>
											<p>2</p>
										</div>
									)
								)}
								{row.original.nextTemplateNumber > 3 &&
								row.original.totalTemplates > 2 ? (
									<img
										className="mhr-sequence-checked-image"
										src={require("../assets/images/sequence-tickcircle.png")}
									/>
								) : (
									row.original.totalTemplates > 2 && (
										<div>
											<p>3</p>
										</div>
									)
								)}
							</div>
						</div>
					)} */}
				</div>
			),
		},
		//Notes
		{
			Header: "Notes",
			accessor: "notes",
			width: 300,
			maxWidth: 300,
			minWidth: 300,
			// minResizeWidth: 120,
			filterMethod: (filter, row) => {
				return row[filter.id]
					.map((e) => e.note)
					.join(",")
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchNotes: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			// Cell: (row) => (
			// 	// <div style={{
			// 	//   alignItems: "center",
			// 	//   justifyContent: "space-between",
			// 	//   display: "flex",
			// 	//   flexDirection: "row",
			// 	//   width: "100%",
			// 	// }}>

			// 	//   <div style={{ display: "flex", flex: 1, paddingLeft: 15 }}>
			// 	<Popup
			// 		contentStyle={{ display: "flow", flex: 1 }}
			// 		trigger={
			// 			<div
			// 				style={{
			// 					flex: 1,
			// 					display: "flex",
			// 					width: "100%",
			// 					height: "100%",
			// 					position: "relative",
			// 				}}
			// 			>
			// 				<NotesTextView
			// 					selectedColumn={this.props.selectedCol}
			// 					showAlert={this.props.showAlert}
			// 					row={row}
			// 					columnName='notes'
			// 					handleSubmit={this.saveNote}
			// 					editableClassName='editableTwoLine'
			// 					isClickable={false}
			// 					isEditable={true}
			// 					allowToUpdate={this.props.allowToUpdate}
			// 					updateDataByID={this.props.updateDataByID}
			// 					users={this.props.users}
			// 					// refreshData={this.refreshData}
			// 					// allowToUpdate={this.allowToUpdate}
			// 				/>
			// 			</div>
			// 		}
			// 		on={"hover"}
			// 		// position="bottom left"
			// 	>
			// 		{IsTextHTML(
			// 			row.value.length > 0
			// 				? row.value[row.value.length - 1].note
			// 				: "plain text"
			// 		) ? (
			// 			<p
			// 				dangerouslySetInnerHTML={{
			// 					__html:
			// 						row.value.length > 0
			// 							? linkifyHtml(row.value[row.value.length - 1].note, {
			// 									target: {
			// 										url: "_blank",
			// 										email: null,
			// 									},
			// 							  })
			// 							: "",
			// 				}}
			// 			></p>
			// 		) : (
			// 			<p className='display-linebreak'>
			// 				{row.value.length > 0
			// 					? linkifyHtml(row.value[row.value.length - 1].note, {
			// 							target: {
			// 								url: "_blank",
			// 								email: null,
			// 							},
			// 					  })
			// 					: ""}
			// 			</p>
			// 		)}
			// 		{/* {row.value.length > 0 ? row.value[row.value.length - 1].note : "n/a"} */}
			// 		<button
			// 			style={{
			// 				color: "#0a84fe",
			// 				fontSize: 10,
			// 				cursor: "pointer",
			// 				merginLeft: 10,
			// 				background: "none",
			// 				border: "none",
			// 			}}
			// 			onClick={() =>
			// 				this.handleCopy(
			// 					row.value.length > 0 ? row.value[row.value.length - 1].note : ""
			// 				)
			// 			}
			// 		>
			// 			<FittedImage
			// 				fit='auto'
			// 				src={copyIcon}
			// 				style={{
			// 					width: 17,
			// 					height: 17,
			// 					marginLeft: 5,
			// 					cursor: "pointer",
			// 				}}
			// 			/>
			// 		</button>
			// 	</Popup>
			// 	//   </div>
			// 	// </div>
			// ),
			Cell: (row) => {
				const note =
					row.original.time_line && row.original.time_line.length !== 0
						? [...row.original.time_line]
								.filter((item) => item.item_type === "note")
								.sort(
									(a, b) => new Date(b.created_at) - new Date(a.created_at)
								)[0]?.note
						: "";

				return (
					<Popup
						contentStyle={{ display: "flow", flex: 1 }}
						trigger={
							<div
								style={{
									flex: 1,
									display: "flex",
									width: "100%",
									height: "100%",
									position: "relative",
								}}
							>
								<NotesTextView
									selectedColumn={this.props.selectedCol}
									showAlert={this.props.showAlert}
									row={row}
									columnName='notes'
									handleSubmit={this.saveNote}
									editableClassName='editableTwoLine'
									isClickable={false}
									isEditable={true}
									allowToUpdate={this.props.allowToUpdate}
									updateDataByID={this.props.updateDataByID}
									users={this.props.allUsers}
								/>
							</div>
						}
						on={"hover"}
					>
						{IsTextHTML(note) ? (
							<p
								dangerouslySetInnerHTML={{
									__html: note?.trim()
										? linkifyHtml(note, {
												target: {
													url: "_blank",
													email: null,
												},
										  })
										: "",
								}}
							></p>
						) : (
							<p className='display-linebreak'>
								{note?.trim()
									? linkifyHtml(note, {
											target: {
												url: "_blank",
												email: null,
											},
									  })
									: ""}
							</p>
						)}

						{/* {row.value.length > 0 ? row.value[row.value.length - 1].note : "n/a"} */}
						<button
							style={{
								color: "#0a84fe",
								fontSize: 10,
								cursor: "pointer",
								merginLeft: 10,
								background: "none",
								border: "none",
							}}
							onClick={() => this.handleCopy(note)}
						>
							<FittedImage
								fit='auto'
								src={copyIcon}
								style={{
									width: 17,
									height: 17,
									marginLeft: 5,
									cursor: "pointer",
								}}
							/>
						</button>
					</Popup>
				);
			},
		},
		//phone
		{
			Header: "Phone",
			accessor: "phone_number",
			width: 350,
			maxWidth: 650,
			minWidth: 220,
			// minResizeWidth: 100,
			filterMethod: (filter, row) => {
				return row[filter.id]
					.toLowerCase()
					.replace(/\(/g, "")
					.replace(/\)/g, "")
					.replace(/-/g, "")
					.replace(/\s/g, "")
					.includes(
						filter.value
							.toLowerCase()
							.replace(/\(/g, "")
							.replace(/\)/g, "")
							.replace(/-/g, "")
							.replace(/\s/g, "")
					);
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchPhoneNumber: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						justifyContent: "space-between",
						display: "flex",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<div
						style={{
							alignItems: "center",
							// justifyContent:"space-around",
							display: "flex",
							flexDirection: "row",
							maxWidth: "100%",
						}}
					>
						<Popup
							trigger={
								<div
									style={{
										marginBottom: 8,
										marginRight: 2,
										marginLeft: 7,
										width: 15,
										minWidth: 15,
										height: 15,
										position: "relative",
									}}
								>
									<img
										onClick={() =>
											this.contactLead(
												row.original._id,
												"phone",
												row.original.assigned_to,
												row.index
											)
										}
										style={{
											cursor: "pointer",
											width: 20,
											height: 20,
										}}
										src={smsIcon}
									/>
									{row.original.auto_contact_count > 0 ? (
										<img
											style={{
												width: 12,
												height: 12,
												top: -2,
												right: -5,
												position: "absolute",
											}}
											src={sentCheckIcon}
										/>
									) : null}
								</div>
							}
							on={"hover"}
							position='top left'
						>
							{row.original.auto_contact_count > 0
								? `Canned sms already sent.`
								: `Send canned sms.`}
						</Popup>
						<div
							style={{
								marginLeft: 10,
								marginRight: 10,
								maxWidth: "100%",
							}}
						>
							<EditableTextView
								showAlert={this.props.showAlert}
								row={row}
								columnName='phone_number'
								handleSubmit={this.handleSubmit}
								editableClassName='editableOneLine'
								isClickable={false}
								isEditable={true}
							/>
						</div>
					</div>

					<Popup
						trigger={
							<div
								style={{
									height: 22,
									width: 65,
									borderRadius: 30,
									borderStyle: "solid",
									borderWidth: 2,
									display: "flex",
									paddingLeft: 4,
									paddingRight: 4,
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<img
									style={{
										width: 13,
										height: 13,
									}}
									src={call}
								/>
								<span
									style={{
										backgroundColor: "gray",
										height: 17,
										width: 17,
										borderRadius: 100,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<p
										style={{
											fontWeight: "bold",
											fontSize: 11,
											textAlign: "center",
											alignSelf: "center",
											color: "white",
											paddingTop: 2,
										}}
									>
										{row.original.email_auto_contact_count +
											row.original.auto_contact_count +
											row.original.auto_review_count +
											row.original.auto_referral_count}
									</p>
								</span>

								{/* <span style={{ backgroundColor: "gray", height: 17, width: 17, borderRadius: 100, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ fontWeight: "bold", fontSize: 11, textAlign: "center", alignSelf: "center", color: "white", paddingTop: 2 }}>
                {row.original.email_auto_contact_count + row.original.auto_contact_count + row.original.auto_review_count + row.original.auto_referral_count}</p></span> */}
							</div>
						}
						on={"hover"}
						position='top left'
					>
						{" "}
						<div>Total Touch points</div>
					</Popup>
					{/* sequence feature commented */}
					{/* {!row.original.sequenceStatusSms ||
					row.original.sequenceStatusSms === "0" ? (
						<div
							className="mhr-seqeunce-container "
							onClick={() => {
								this.setState({
									showStartSequenceModal: true,
									rowId: row.original._id,
									email: false,
								});
							}}
						>
							<img
								src={require("../assets/images/sequence-play.png")}
							/>
							<p>Sequence</p>
						</div>
					) : row.original.sequenceStatusSms === "2" ? (
						<div
							className="mhr-seqeunce-container mhr-seqeunce-container-yellow "
							onClick={async () => {
								const currentDate = new Date();
								// const date = await formatDate(
								//   row.original.nextTemplateDateSmsOriginal
								// );
								const passed = await hasDatePassed(
									row.original.nextTemplateDateSms,
									currentDate.toLocaleDateString("en-US")
								);
								if (passed) {
									this.setState({
										unPauseId:
											row.original.assignedSequenceSms,
										showUpdateDatePopup: true,
										pause: false,
										email: false,
										rowId: row.original._id,
									});
								} else {
									this.setState({
										showPauseSequenceModalSms: true,
										pause: false,
										email: false,
										leadId: row.original._id,
									});
								}
							}}
						>
							<img
								src={require("../assets/images/sequence-paused.png")}
							/>
							<p>Sequence</p>
						</div>
					) : row.original.nextTemplateNumberSms ===
							row.original.totalTemplatesSms + 1 ||
					  row.original.sequenceStatusSms === "3" ? (
						<div
							className="mhr-seqeunce-container mhr-seqeunce-container-green"
							onClick={() => {
								this.props.showAlert(
									"Sequence already completed"
								);
								this.setState({
									showPauseSequenceDetailsModal: true,
									email: false,
									showPauseButton: false,
									pause: true,
									sequenceId:
										row.original.assignedSequenceSms,
									totalTemplates:
										row.original.totalTemplatesSms,
									startDate:
										row.original.startTemplateDateSms,
									nextTemplateNumber:
										row.original.nextTemplateNumberSms,
									firstTemplateDate:
										row.original.firstTemplateDateSms,
									secondTemplateDate:
										row.original.secondTemplateDateSms,
									thirdTemplateDate:
										row.original.thirdTemplateDateSms,
									leadId: row.original._id,
									selectedSequence: row.original.smsSequence,
								});
							}}
						>
							<p>Sequence</p>
							<img
								src={require("../assets/images/sequence-tickcircle-green.png")}
							/>
						</div>
					) : (
						row.original.sequenceStatusSms === "1" && (
							<div className="mhr-seqeunce-container-played">
								<img
									className="mhr-seqeunce-container-played-img"
									src={require("../assets/images/sequence-pause.png")}
									onClick={() => {
										this.setState({
											showPauseSequenceModal: true,
											pause: true,
											email: false,
											leadId: row.original._id,
										});
									}}
								/>
								<div
									onClick={() =>
										this.setState({
											showPauseSequenceDetailsModal: true,
											email: false,
											showPauseButton: true,
											pause: true,
											sequenceId:
												row.original
													.assignedSequenceSms,
											totalTemplates:
												row.original.totalTemplatesSms,
											startDate:
												row.original
													.startTemplateDateSms,
											nextTemplateNumber:
												row.original
													.nextTemplateNumberSms,
											firstTemplateDate:
												row.original
													.firstTemplateDateSms,
											secondTemplateDate:
												row.original
													.secondTemplateDateSms,
											thirdTemplateDate:
												row.original
													.thirdTemplateDateSms,
											leadId: row.original._id,
											selectedSequence:
												row.original.smsSequence,
										})
									}
								>
									{row.original.nextTemplateNumberSms > 1 &&
									row.original.totalTemplatesSms > 0 ? (
										<img
											className="mhr-sequence-checked-image"
											src={require("../assets/images/sequence-tickcircle.png")}
										/>
									) : (
										row.original.totalTemplatesSms > 0 && (
											<div>
												<p>1</p>
											</div>
										)
									)}
									{row.original.nextTemplateNumberSms > 2 &&
									row.original.totalTemplatesSms > 1 ? (
										<img
											className="mhr-sequence-checked-image"
											src={require("../assets/images/sequence-tickcircle.png")}
										/>
									) : (
										row.original.totalTemplatesSms > 1 && (
											<div>
												<p>2</p>
											</div>
										)
									)}
									{row.original.nextTemplateNumberSms > 3 &&
									row.original.totalTemplatesSms > 3 ? (
										<img
											className="mhr-sequence-checked-image"
											src={require("../assets/images/sequence-tickcircle.png")}
										/>
									) : (
										row.original.totalTemplatesSms > 2 && (
											<div>
												<p>3</p>
											</div>
										)
									)}
								</div>
							</div>
						)
					)} */}
				</div>
			),
		},
		// Reminder column
		// {
		//   Header: "Reminder",
		//   accessor: "auto_referral_count",
		//   filterable: false,
		//   width: 90,
		//   maxWidth: 250,
		//   minWidth: 60,
		//   minResizeWidth: 60,
		//   Cell: (row) => (
		//     <div
		//       style={{
		//         justifyContent: "center",
		//         alignItems: "center",
		//         display: "flex",
		//         flexDirection: "row",
		//         width: "80%",
		//       }}
		//     >

		//       {/* {row.original.auto_review_count == 0 || row.original.auto_referral_count == 0 ? (
		//         <Popup
		//           trigger={
		//             <div
		//               style={{
		//                 marginBottom: 8,
		//                 marginRight: 2,
		//                 marginLeft: 7,
		//                 width: 15,
		//                 minWidth: 15,
		//                 height: 15,
		//                 position: "relative",
		//               }}
		//             >
		//               <img
		//                 onClick={() =>

		//                   this.contactLead(
		//                     row.original._id,
		//                     "review",
		//                     row.original.assigned_to,
		//                     row.index
		//                   )
		//                 }
		//                 style={{ cursor: "pointer", width: "28px", height: "25px" }}
		//                 src={reminder}
		//               />
		//               {row.original.auto_referral_count > 0 ? (
		//                 <img
		//                   style={{
		//                     width: 10,
		//                     height: 10,
		//                     top: -2,
		//                     right: -10,
		//                     position: "absolute",
		//                   }}
		//                   src={orangeIcon}
		//                 />
		//               ) : ''}
		//               {row.original.auto_review_count > 0 ? (
		//                 <img
		//                   style={{
		//                     width: 10,
		//                     height: 10,
		//                     top: -2,
		//                     right: 1,
		//                     position: "absolute",
		//                   }}
		//                   src={sentCheckIcon}
		//                 />
		//               ) : ''}
		//             </div>
		//           }

		//           on={"hover"}
		//           position="top right"
		//         >

		//           <div>Send Review/Referral Message.</div>
		//         </Popup>

		//       ) : */}

		//       <Popup
		//         trigger={
		//           <div
		//             style={{
		//               marginBottom: 8,
		//               marginRight: 2,
		//               marginLeft: 7,
		//               width: 25,
		//               minWidth: 15,
		//               height: 15,
		//               position: "relative",
		//             }}
		//           >
		//             <img
		//               // onClick={() =>

		//               //   this.contactLead(
		//               //     row.original._id,
		//               //     "review",
		//               //     row.original.assigned_to,
		//               //     row.index
		//               //   )
		//               // }
		//               style={{ cursor: "pointer", width: "28px", height: "25px" }}
		//               src={reminder}
		//             />

		//             {row.original.auto_review_count > 0 ? (
		//               <img
		//                 style={{
		//                   width: 10,
		//                   height: 10,
		//                   bottom: -14,
		//                   right: -2,
		//                   position: "absolute",
		//                 }}
		//                 src={sentCheckIcon}
		//               />
		//             ) : ''}
		//           </div>
		//         }

		//         on={"hover"}
		//         position="top right"
		//       >

		//         <div>Feature coming soon...</div>
		//       </Popup>

		//       {/* <EditableTextView
		//         showAlert={this.props.showAlert}
		//         row={row}
		//         columnName=""
		//         handleSubmit={this.handleSubmit}
		//         editableClassName="editableOneLine"
		//         isClickable={false}
		//         isEditable={true}
		//       /> */}
		//     </div>
		//   ),
		// },
		{
			Header: "Reminder",
			accessor: "reminder",
			filterable: true,
			resizable: false,
			width: 90,
			maxWidth: 250,
			minWidth: 60,
			minResizeWidth: 60,
			Filter: ({ filter, onChange }) => (
				<select
					className='select'
					onChange={(event) => {
						onChange(event.target.value);
					}}
					style={{
						width: "100%",
						height: 22,
						textAlignLast: "left",
						fontSize: 10,
					}}
					key={"reminder_filter"}
					value={filter ? filter.value : "All"}
				>
					<option value='All'>All</option>
					<option value='Unset'>Unset</option>
					<option value='Set'>Set</option>
				</select>
			),
			filterMethod: (filter, row) => {
				let bol = row._original?.reminderToShow;
				switch (filter.value) {
					case "All":
						return true;
					case "Unset":
						return !bol;
					case "Set":
						return bol;
					default:
						return true;
				}
			},
			Cell: (row) => (
				<Popup
					trigger={
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => {
								if (
									!row.original.assigned_to ||
									(row.original.assigned_to !==
										localStorage.getItem("username") &&
										!row.original.reminderToShow) ||
									(row.original.assigned_to !==
										localStorage.getItem("username") &&
										row.original.reminderToShow?.creator_id !==
											localStorage.getItem("userId") &&
										localStorage.getItem("isAdmin") != "admin")
								) {
									let message =
										"You are about to re-assign this lead to yourself from " +
										row.original.assigned_to +
										". Are you sure?";
									if (
										!row.original.assigned_to ||
										row.original.assigned_to == ""
									) {
										message =
											"You are about to assign this lead to yourself. Are you sure?";
									}
									this.props.confirmAction(
										"Confirmation",
										message,
										() => {
											this.updateAssignee(
												localStorage.getItem("email"),
												row.original._id,
												0
											);
										},
										() => {}
									);
								} else
									this.setState({
										reminderModal: true,
										rowId: row.original._id,
										leadName: row.original.name,
										updateReminder:
											row.original.reminderToShow &&
											row.original.reminderToShow.reminder_status
												? row.original.reminderToShow.reminder_status
												: false,
										reminderId:
											row.original.reminderToShow &&
											row.original.reminderToShow.creator_id
												? row.original.reminderToShow.creator_id
												: "",
										reminderDate:
											row.original.reminderToShow &&
											row.original.reminderToShow.reminder_date
												? row.original.reminderToShow.reminder_date
												: "",
										reminderDescription:
											row.original.reminderToShow &&
											row.original.reminderToShow.reminder_description
												? row.original.reminderToShow.reminder_description
												: "",
										reminderAdmin:
											localStorage.getItem("isAdmin").toLowerCase() ===
												"admin" &&
											row.original.assigned_to !==
												localStorage.getItem("username")
												? true
												: false,
										leadAssignedTo: row.original.assigned_to,
									});
							}}
						>
							{(row.original.assigned_to === localStorage.getItem("username") &&
								row.original.reminderToShow?.creator_id ===
									localStorage.getItem("userId")) ||
							(localStorage.getItem("isAdmin").toLowerCase() === "admin" &&
								row.original.reminderToShow?.created_by &&
								row.original.assigned_to ==
									row.original.reminderToShow?.created_by) ? (
								row.original.temp_color ? (
									<div style={{ position: "relative" }}>
										<img
											src={require("../assets/gif/reminder-dancing.gif")}
											style={{
												width: "36px",
												height: "36px",
												cursor: "pointer",
											}}
										/>
										<img
											src={require("../assets/images/sequence-tickcircle.png")}
											style={{
												position: "absolute",
												right: "0",
												bottom: "0",
												marginBottom: "-4px",
												marginRight: "3px",
												width: "15px",
												height: "15px",
											}}
										/>
									</div>
								) : (
									<img
										src={require("../assets/images/reminder-set.png")}
										style={{
											width: "30px",
											height: "30px",
											cursor: "pointer",
										}}
									/>
								)
							) : (
								<img
									src={require("../assets/images/reminder-unset.png")}
									style={{
										width: "28px",
										height: "28px",
										cursor: "pointer",
									}}
								/>
							)}
						</div>
					}
					on={"hover"}
					position='bottom right'
				>
					<div>
						{row.original.reminderToShow &&
						row.original.reminderToShow?.creator_id ===
							localStorage.getItem("userId") ? (
							row.original?.reminderToShow?.reminder_date.toString() ===
							this.props.today.toString() ? (
								row.original?.reminderToShow?.reminder_description ? (
									<div
										dangerouslySetInnerHTML={{
											__html:
												row.original?.reminderToShow?.reminder_description,
										}}
									></div>
								) : (
									"Edit reminder for this lead"
								)
							) : (
								"Edit reminder for this lead"
							)
						) : (
							"Set reminder for this lead"
						)}
					</div>
				</Popup>
			),
		},

		//Hotlist
		{
			Header: "Hotlist",
			accessor: "starred_by_user_ids",
			filterable: false,
			width: 80,
			maxWidth: 80,
			minWidth: 60,
			// minResizeWidth: 60,
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignSelf: "center",
						width: "100%",
					}}
				>
					<Popup
						trigger={
							<div
								style={{
									width: "40%",
									height: "100%",
									position: "relative",
									display: "flex",
									justifyContent: "center",
									position: "relative",
								}}
							>
								<img
									onClick={() =>
										this.updateLeadStar(
											row.original._id,
											row.original.assigned_to,
											row.index,
											row
										)
									}
									style={{
										cursor: "pointer",
										width: "26px",
										height: "24px",
									}}
									//src={starIcon}

									src={row.original.starred_by_user_ids ? unstarIcon : starIcon}
								/>
							</div>
						}
						on={"hover"}
						position='top right'
					>
						{row.original.starred_by_user_ids > 0
							? `Remove from hotlist.`
							: `Add to hotlist.`}
					</Popup>
				</div>
			),
		},
		{
			Header: "Spam",
			accessor: "isLeadSpam",
			filterable: false,
			width: 90,
			maxWidth: 250,
			minWidth: 60,
			minResizeWidth: 60,
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<Popup
						trigger={
							<div
								style={{
									width: "40%",
									height: "100%",
									position: "relative",
									display: "flex",
									justifyContent: "center",
									position: "relative",
								}}
							>
								<img
									onClick={async () =>
										await updateLeadSpamStatus(row.original._id)
									}
									style={{
										cursor: "pointer",
										width: "26px",
										height: "26px",
									}}
									src={row.original.isLeadSpam ? spamActive : spamInactive}
								/>
								{row.original.isLeadSpam}
							</div>
						}
						on={"hover"}
						position='top right'
					>
						{!row.original.isLeadSpam
							? "Add lead to spam."
							: "Remove from spam."}
					</Popup>
				</div>
			),
		},

		//Sold
		{
			Header: "Sold",
			accessor: "archived",
			filterable: false,
			width: 80,
			maxWidth: 80,
			minWidth: 60,
			// minResizeWidth: 60,
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignSelf: "center",
						width: "100%",
					}}
				>
					<Popup
						trigger={
							<div
								style={{
									width: "40%",
									height: "100%",
									position: "relative",
									display: "flex",
									justifyContent: "center",
									position: "relative",
								}}
							>
								<p
									onClick={() =>
										this.updateSoldStatus(
											row.original._id,
											row.original.assigned_to,
											row.index,
											row
										)
									}
									style={{
										cursor: "pointer",
										fontWeight: "bold",
										fontSize: 25,
										color: row.original.sold_status ? "#66BB6A" : "#666464",
									}}
								>
									$
								</p>
							</div>
						}
						on={"hover"}
						position='top right'
					>
						{row.original.sold_status ? `Remove from sold.` : `Add to sold.`}
					</Popup>
				</div>
			),
		},
		{
			Header: "Assigned To",
			accessor: "assigned_to",
			width: 200,
			maxWidth: 200,
			minWidth: 80,
			// minResizeWidth: 80,

			filterMethod: (filter, row) => {
				this.filteredValue = filter.value;
				if (filter.value === "All") return true;
				if (filter.value.toString() == "Un-Assign") {
					if (row[filter.id] == null || row[filter.id] == "") {
						return true;
					} else return false;
				}

				if (row[filter.id] == null || row[filter.id].toString().trim() == "")
					return false;
				try {
					var flag =
						row[filter.id].toString().toLowerCase() ==
						filter.value.toString().toLowerCase();
					//.includes(filter.value.toString().toLowerCase());
					return flag;
				} catch (e) {
					return true;
				}
			},
			Filter: ({ filter, onChange }) => (
				<select
					className='select'
					defaultValue={localStorage.getItem("username")}
					onChange={(event) => {
						this.selectedAssignee = event.target.value;
						this.props.allowToUpdate();
						if (event.target.value == "All") this.setState({ pageSize: 30 });
						else this.setState({ pageSize: 50 });
						onChange(event.target.value);
					}}
					style={{
						width: "100%",
						height: 22,
						textAlign: "center",
						textAlignLast: "left",
						fontSize: 11,
					}}
					key={"assigned_to_filter"}
				>
					<option value='All'>All</option>
					{this.props.users?.map((user) => (
						<option key={user.value} value={user.name}>
							{user.label}
						</option>
					))}
					;
				</select>
			),

			Cell: (row) => {
				let userss = [...(this.props?.users || [])];
				// let userss = [...this.props?.users]
				return (
					<NewAssigneeDropdown
						row={row}
						rowValue={row.value}
						users={userss}
						updateAssignee={this.updateAssignee}
					/>
				);
			},
			style: { overflow: "visible", paddingLeft: 10, paddingRight: 10 },
		},

		{
			Header: "",
			accessor: "actions",
			filterable: false,
			width: window.screen.width * 0.27,
			// maxWidth: 250,
			// minWidth: 60,
			// minResizeWidth: 60,
			Cell: (row) => <></>,
		},
	];
	//table end

	state = {
		pageSize: 30,
		selectedRowIndex: -1,
		filteredData: [],
		isLoading: false,
		timeCompleted: true,
		TotalNumberOfPages: 0,
		isFiltered: false,
		pageNum: 0,
		showSnoozeModal: false,
		searchInProgress: false,
		renderFilter: false,
		filteredLeadsData: [],
		autoFollowUpMessage: "my messasge",
		/** for search query */
		// searchText: "",
		// searchName: "",
		// searchSource:  "",
		// searchChannel: "",
		// searchEmail: "",
		// searchAddress: "",
		// searchPhoneNumber: "",
		// searchSvcRequested: "",
		// searchNotes: "",
		// searchExtraLinks: "",
		sortedLeadData: this.props.leads,
		notesLead: null,
		tempLeadsData: null,
		showStartSequenceModal: false,
		showSequenceDetailsModal: false,
		showPauseSequenceModal: false,
		showPauseSequenceDetailsModal: false,
		showPauseSequenceModalSms: false,
		unPauseSequenceModal: false,
		showUpdateDatePopup: false,
		rowId: null,
		email: true,
		sequenceId: null,
		totalTemplates: null,
		startDate: null,
		leadId: null,
		pause: false,
		unPauseId: null,
		showPauseButton: true,
		selectedSequence: {},
		firstTemplateDate: null,
		secondTemplateDate: null,
		thirdTemplateDate: null,
		//==== Reminder ======
		reminderModal: false,
		updateReminder: false,
		leadName: "",
		reminderId: "",
		reminderDate: "",
		reminderDescription: "",
	};

	componentDidUpdate(prevProps) {
		if (prevProps.leads.length !== this.props.leads.length)
			console.log("we are here", this.props.leads.length);
		if (
			(prevProps.allLeads.length !== this.props.allLeads.length &&
				this.props.leads.length <= 30) ||
			(prevProps.leads.length !== this.props.leads.length &&
				this.props.leads.length <= 30)
		) {
			this.props.fetchMoreData(this.state.pageSize, this.state.pageIndex);
		}
	}

	//   this.setState({ searchInProgress: true })
	//   console.log("api parameters", this.state.pageSize, this.state.pageIndex)
	//   axios
	//     .get(

	//       baseURL() +
	//       "/api/v1/leads/staredLeads?limit=" +
	//       400 +
	//       "&selection_string=" +
	//       this.selectionStrings,
	//       {
	//         headers: {
	//           "x-access-token": localStorage.getItem("jwtToken"),
	//           "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
	//         },
	//       }
	//       // baseURL() + "/api/v1/leads/staredLeads?limit=" +
	//       // this.state.pageSize * 3 +
	//       // "&skip=" +
	//       // (1),
	//       // "&selection_string=" +
	//       // this.selectionStrings,

	//       // {
	//       //   headers: {
	//       //     "x-access-token": localStorage.getItem("jwtToken"),
	//       //     "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
	//       //   },
	//       // }
	//     )
	//     .then((res) => {
	//       this.setState({ searchInProgress: false })

	//       // var starLeads = res.data.data.filter(
	//       //   (lead) => lead.starred_by_user_ids
	//       // );
	//       this.setState({
	//         sortedLeadData: res.data.data
	//       });
	//       console.log("----------starleads data-----", res.data.data);
	//       // this.LeadsScreenRef.setState({ tempLeadsData: res.data.data });
	//     })
	//     .catch((error) => {
	//       this.setState({ searchInProgress: false })
	//       if (error.toString().includes("403")) {
	//         // this.onLogoutClick();
	//       }
	//     });

	// }
	selectAllCheckbox() {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					marginTop: 30,
					backgroundColor: "red",
				}}
			>
				<input
					className='checkbox-input1'
					style={{ transform: "scale(2.5)", height: 80 }}
					type='checkbox'
					checked={this.state.selectAll}
					onChange={this.handleSelectAll}
				/>
			</div>
		);
	}
	clearChecks = () => {
		this.setState({ selectedEmails: [], selectAll: false });
	};

	handleCopy = (str) => {
		if (str != "") {
			function listener(e) {
				e.clipboardData.setData("text/html", str);
				e.clipboardData.setData("text/plain", str);
				e.preventDefault();
			}
			document.addEventListener("copy", listener);
			document.execCommand("copy");
			document.removeEventListener("copy", listener);
			// var strippedHtml = text.replace(/<[^>]+>/g, '');
			// copy(strippedHtml);
			this.props.showAlert("Copied to clipboard", 1000);
		}
	};
	handelSelectedEmails = (newArray) => {
		this.setState({ selectedEmails: newArray });
	};
	refreshData = () => {
		let leads = this.props.leads;
		var index = this.props.leads.findIndex((x) => {
			return x._id == this.state.notesLead?._id;
		});
		leads[index] = this.state.notesLead;
		var sortedData = leads.sort((a, b) =>
			a.updated_at > b.updated_at ? 1 : -1
		);
		this.setState({
			sortedLeadData: sortedData,
		});
		// this.setState({ searchInProgress: true })

		// axios
		//   .get(

		//     baseURL() +
		//     "/api/v1/leads/staredLeads?limit=" +
		//     400 +
		//     "&selection_string=" +
		//     this.selectionStrings,
		//     {
		//       headers: {
		//         "x-access-token": localStorage.getItem("jwtToken"),
		//         "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
		//       },
		//     }
		//     // baseURL() + "/api/v1/leads/staredLeads?limit=" +
		//     // this.state.pageSize * 3 +
		//     // "&skip=" +
		//     // (1),
		//     // "&selection_string=" +
		//     // this.selectionStrings,

		//     // {
		//     //   headers: {
		//     //     "x-access-token": localStorage.getItem("jwtToken"),
		//     //     "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
		//     //   },
		//     // }
		//   )
		//   .then((res) => {
		//     this.setState({ searchInProgress: false })
		//     // var starLeads = res.data.data.filter(
		//     //   (lead) => lead.starred_by_user_ids
		//     // );
		//     this.setState({
		//       sortedLeadData: res.data.data
		//     });
		//     console.log("----------starleads data-----", res.data.data);
		//     // this.LeadsScreenRef.setState({ tempLeadsData: res.data.data });
		//   })
		//   .catch((error) => {
		//     this.setState({ searchInProgress: false })
		//     if (error.toString().includes("403")) {
		//       // this.onLogoutClick();
		//     }
		//   });
	};
	// trimArray = (arr) => {
	// 	if (arr.length > 30) {
	// 		arr.reverse(); // Reverse the array
	// 		arr = arr.slice(0, 30); // Keep the first 30 elements of the reversed array (which are the last 30 elements of the original array)
	// 		arr.reverse();
	// 	}
	// 	return arr;
	// };
	handleSelectAll = (e) => {
		let filteredLeads = [];
		console.log("this.filteredValue", this.filteredValue);
		if (this.filteredValue === "All") {
			filteredLeads = this.props.leads;
		} else {
			filteredLeads = this.props.leads.filter((lead) => {
				return lead.assigned_to == this.filteredValue;
			});
		}
		// console.log("filteredLleads", filteredLeads);
		// trimmedLeads = this.trimArray(filteredLeads);
		const { checked } = e.target;
		const allEmails = filteredLeads.map((lead, index) => ({
			name: lead.name,
			email: lead.email,
			id: lead._id,
			index: index,
		}));
		console.log("allEmails", allEmails);
		this.setState({
			selectAll: checked,
			selectedEmails: checked ? allEmails : [],
		});
	};

	handleCheckboxChange = (e, name, email, id) => {
		const { checked } = e.target;
		console.log("Checkbox clicked:", checked, "Name:", name, "Email:", email);

		if (checked) {
			// Add the selected email to the state
			this.setState(
				(prevState) => ({
					selectedEmails: [
						...(prevState?.selectedEmails || []),
						{ name, email, id },
					],
				}),
				() => {
					console.log("Selected emails:", this.state.selectedEmails);
				}
			);
		} else {
			// Remove the deselected email from the state
			this.setState(
				(prevState) => ({
					selectedEmails: (prevState?.selectedEmails || []).filter(
						(selectedEmail) =>
							!(selectedEmail.name === name && selectedEmail.email === email)
					),
				}),
				() => {
					console.log("Selected emails:", this.state.selectedEmails);
				}
			);
		}
	};

	reOrderAndResizeColumns = (order, columnSize, columns) => {
		let orders = [
			"snooze",
			"name",
			"email",
			"notes",
			"phone_number",
			"source",
			"utc_time_stamp",
			"starred_by_user_ids",
			"address",
			"auto_referral_count",
			"svc_requested",
			"extra_links",
			"assigned_to",
			"archived",
			"actions",
			"channel",
			"sold",
			"isLeadSpam",
		];

		// let orders = JSON.parse(order);
		for (let i = 0; i < orders.length; i++) {
			const index = columns.findIndex((item) => item.accessor === orders[i]);
			if ((index) => 0) {
				columns = arrayMove(columns, index, i);
			}
		}

		for (let i = 0; i < columnSize.length; i++) {
			const index = columns.findIndex(
				(item) => item.accessor === columnSize[i].column
			);
			if ((index) => 0) {
				if (
					columnSize[i].size != null &&
					columnSize[i].size !== "" &&
					columns[index] !== undefined
				)
					columns[index].width = 340;
				// columns[index].width = Number.parseInt(columnSize[i].size);
			}
		}

		//
		return columns;
	};
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.users = props.users;
		this.myRef = React.createRef();
		// this.firstColumns = this.reOrderAndResizeColumns(
		//   this.props.columnsOrder,
		//   this.props.columnsSize,
		//   this.firstColumns
		// );
		localStorage.setItem("archiveFilterValue", "Un-Archived");
		localStorage.setItem("starFilterValue", "star");
		localStorage.setItem("timeFilterValue", "All");
	}

	componentWillMount() {
		if (this.state.tempLeadsData == null) {
			this.setState({
				tempLeadsData: this.props.tempData,
			});
		}
	}
	saveAutoMessage = (message, emailAttachment, test_receiver) => {
		console.log("saveAutoMessage", message);
		this.myMessage = message;
		this.emailAttachment = emailAttachment;
		this.test_receiver = test_receiver;

		// this.setState({ autoFollowUpMessage: message })
	};
	handleOpenFollowUpMode = () => {
		this.setState({ showAutoFollowUpModal: true });
	};
	handleSetTitle = (val) => {
		this.setState({ multiEmailSmsTitle: val });
	};
	handleCloseFollowUpMode = () => {
		this.setState({ showAutoFollowUpModal: false });
	};
	handleSubmit = (key, oldValue, value, leadID) => {
		console.log("handle_submit", key, oldValue, value, leadID);
		//
		if (oldValue === value) {
			return;
		}

		//
		//
		axios
			.put(
				baseURL() + "/api/v1/leads/" + leadID,
				{
					[key]: value,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateDataByID(leadID, res.data.data);
				// this.props.showAlert("Changes Saved");
			})
			.catch((error) => {
				this.setState({
					isInProgress: false,
				});
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	saveNote = (key, oldValue, value, leadID) => {
		const userName = localStorage.getItem("username");
		console.log("notes api", value, leadID);
		//
		if (oldValue === value) {
			return;
		}

		axios
			.post(
				baseURL() + "/api/v1/leads/note",
				{
					lead_id: leadID,
					added_by: userName ? userName : "Web Portal",
					text: value,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				// console.log("notess", res);
				this.setState({ notesLead: res.data.data });
				this.props.allowToUpdate();
				this.props.updateDataByID(leadID, res.data.data);
			})
			.catch((error) => {
				this.setState({
					isInProgress: false,
				});
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	deleteLead = (id, callback) => {
		//
		axios
			.delete(baseURL() + "/api/v1/leads/" + id, {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					socket_id: localStorage.getItem("socket_id"),
				},
			})
			.then((res) => {
				//
				// this.props.allowToUpdate();
				// this.props.deleteLead(id);
				callback();
			})
			.catch((error) => {
				this.setState({
					isInProgress: false,
				});
				this.props.showAlert("Failed to save changes, please try again");
			});
	};

	confirmAction = (title, message, yesCallback, lead) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<FollowUpModal
						title={title == "phone" ? "SMS" : title}
						message={message}
						lead={lead}
						leadName={lead.name}
						handleYes={yesCallback}
						handleNo={onClose}
						autoSave={this.saveAutoMessage}
						showAlert={this.props.showAlert}
						show={false}
					/>
				);
			},
			// title: title,
			// message: message,
			// buttons: [
			//   {
			//     label: "Yes",
			//     onClick: () => yesCallback(),
			//   },
			//   {
			//     label: "No",
			//     onClick: () => noCallback(),
			//   },
			// ],
		});
	};
	autoFollowUpApiCallForBulk = async (id = null, type) => {
		console.log(
			"bulk email",
			this.state.selectedEmails.length,
			this.myMessage,
			this.emailAttachment,
			type
		);
		// const idsArray = this.state.selectedEmails.map((item) => item.id);
		const leadIDsSet = new Set(
			this.state.selectedEmails.map((item) => item.id)
		);
		const idsArray = [...leadIDsSet];

		const leadEmailsSet = new Set(
			this.state.selectedEmails.map((item) => item.email)
		);
		const emailArray = [...leadEmailsSet];
		console.log("idss array", idsArray);
		this.props.showProgressAlert(`Sending ${type} in progress`);
		// this.props.onPressContactLead();
		await axios
			.post(
				baseURL() + "/api/v1/leads/auto-followup-bulk",
				{
					ids: idsArray,
					emails: emailArray,
					via: type,
					message: this.myMessage,
					attachment: this.emailAttachment.file_name
						? this.emailAttachment
						: null,
				},
				{
					// timeout: 40000,
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.showAlert("Successfully sent", 500);

				this.props.allowToUpdate();

				this.clearChecks();
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					isInProgress: false,
				});
				this.props.updateLeadAfterAutoFollowup(id, null);
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	autoFollowUpApiCall = async (id, type) => {
		this.props.onPressContactLead();
		await axios
			.post(
				baseURL() + "/api/v1/leads/auto-followup",
				{
					lead_id: id,
					via: type,
					message: this.myMessage,
					attachment: this.emailAttachment.file_name
						? this.emailAttachment
						: null,
					test_receiver: this.test_receiver ? this.test_receiver : null,
				},
				{
					timeout: 5000,
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				if (!res.data.success) {
					this.props.showAlert(res.data.message);
					this.props.updateLeadAfterAutoFollowup(id, null);
					return;
				}
				this.props.allowToUpdate();
				// var currentUser = this.props.users.find(x=>x.id == res.data.data.assign_to_id)
				// this.assigneeDropDown.onUpdateAssignee(currentUser)
				this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					isInProgress: false,
				});
				this.props.updateLeadAfterAutoFollowup(id, null);
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	//contact Lead function for canned messages
	contactLead = (id, type, email, index, callback) => {
		let messageToshow = "";
		var lead = this.props.leads.find((x) => x._id == id);

		if (type == "email") {
			//for email
			messageToshow = "Are you sure you want to send canned email?";
			// if (lead.email_auto_contact_count > 0) {
			//   this.props.showAlert("Canned email already sent");
			//   return;
			// }
			if (lead.email == "" || lead.email == "N/A") {
				this.props.showAlert("Invalid email.");
				return;
			}
		} else if (type == "phone") {
			//for phone
			messageToshow = "Are you sure you want to send canned sms?";
			// if (lead.auto_contact_count > 0) {
			//   this.props.showAlert("Canned sms already sent");
			//   return;
			// }
			if (
				lead.phone_number == "" ||
				lead.phone_number == "N/A" ||
				lead.phone_number.includes(".")
			) {
				this.props.showAlert("Invalid phone number.");
				return;
			}
		} else if (type == "review") {
			//for phone
			//messageToshow = "Are you sure you want to send canned sms?";
			if (lead.auto_review_count > 0 && lead.auto_referral_count > 0) {
				this.props.showAlert("Reveiw and Referral messages already sent");
				return;
			}
		}

		if (type == "phone") {
			var date = new Date(); //calculateZoneSpecificTime(-6)  //-5 central standard time
			var timeInMinutes =
				date.getHours() * 60 + date.getMinutes() + date.getSeconds() / 60;
			// if time in not between 9-5 and lead is auto contacted return disabled

			if (timeInMinutes < 420 || timeInMinutes > 1080) {
				this.props.showAlert(
					"Canned sms/Review can only be sent between 7am to 6pm CST"
				);
				return;
			}
		}

		if (
			email != null &&
			localStorage.getItem("username") != email &&
			localStorage.getItem("isAdmin") != "admin"
		) {
			let message =
				"You are about to re-assign this lead to yourself from " +
				email +
				". Are you sure?";
			if (!email || email == "") {
				message =
					"You are about to assign this lead to yourself. Are you sure?";
			}
			this.props.confirmAction(
				"Confirmation",
				message,
				() => {
					this.updateAssignee(localStorage.getItem("email"), id, index);
				},
				() => {}
			);
		} else {
			if (type == "review") {
				this.props.confirmReviewAction(
					lead,
					"Contact Lead",
					messageToshow,
					() => {},
					async (type) => {
						console.log("------review ation------", type);

						this.props.onPressContactLead();
						await axios
							.post(
								baseURL() + "/api/v1/leads/auto-followup",
								{
									lead_id: id,
									via: type,
								},
								{
									timeout: 5000,
									headers: {
										"x-access-token": localStorage.getItem("jwtToken"),
										"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
										socket_id: localStorage.getItem("socket_id"),
									},
								}
							)
							.then((res) => {
								if (!res.data.success) {
									this.props.showAlert(res.data.message);
									this.props.updateLeadAfterAutoFollowup(id, null);
									return;
								}
								this.props.allowToUpdate();
								// var currentUser = this.props.users.find(x=>x.id == res.data.data.assign_to_id)
								// this.assigneeDropDown.onUpdateAssignee(currentUser)
								this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
							})
							.catch((error) => {
								console.log(error);
								this.setState({
									isInProgress: false,
								});
								this.props.updateLeadAfterAutoFollowup(id, null);
								this.props.showAlert(
									"Failed to save changes, please try again"
								);
							});
					}
				);
			} else {
				console.log("typee--", type);

				this.confirmAction(
					type,
					messageToshow,
					() => this.autoFollowUpApiCall(id, type),
					lead
				);

				return;
				this.props.confirmAction(
					"Contact Lead",

					messageToshow,

					async () => {
						this.props.onPressContactLead();
						await axios
							.post(
								baseURL() + "/api/v1/leads/auto-followup",
								{
									lead_id: id,
									via: type,
								},
								{
									timeout: 5000,
									headers: {
										"x-access-token": localStorage.getItem("jwtToken"),
										"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
										socket_id: localStorage.getItem("socket_id"),
									},
								}
							)
							.then((res) => {
								if (!res.data.success) {
									this.props.showAlert(res.data.message);
									this.props.updateLeadAfterAutoFollowup(id, null);
									return;
								}
								this.props.allowToUpdate();
								// var currentUser = this.props.users.find(x=>x.id == res.data.data.assign_to_id)
								// this.assigneeDropDown.onUpdateAssignee(currentUser)
								this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
							})
							.catch((error) => {
								console.log(error);
								this.setState({
									isInProgress: false,
								});
								this.props.updateLeadAfterAutoFollowup(id, null);
								this.props.showAlert(
									"Failed to save changes, please try again"
								);
							});
					},

					() => {}
				);
			}
		}
	};
	showLoader = () => {
		//
		//  this.props.setLoadingStatus(true);
	};
	shouldComponentUpdate(nextProps, nextState) {
		return this.props.isDataUpdated();
	}

	onExportCustomersClick = () => {
		this.dataUpdated = true;
		let temp = this.state.selectedTabIndex === 0 ? 95 : 170;
		this.setState({ isExported: true });
		if (this.state.showExportCustomersView) {
			this.props.allowToUpdate();
			this.setState({
				innerAvailableHeight: this.state.innerAvailableHeight + temp,
			});
		} else {
			this.props.allowToUpdate();
			this.setState({
				innerAvailableHeight: this.state.innerAvailableHeight - temp,
			});
		}
		this.setState({
			showExportCustomersView: !this.state.showExportCustomersView,
		});
	};

	onExportCustomersCancelButtonClick = () => {
		this.props.allowToUpdate();
		this.setState({ isExported: false });
	};

	updateAssignee = (assign_to, lead_id, index) => {
		// this.props.allowToUpdate();

		axios
			.post(
				baseURL() + "/api/v1/leads/assign-lead/",
				{
					lead_id: lead_id,
					assign_to: assign_to,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data, true);
				// this.props.updateAssigne(this.selectedRowIndex,assign_to);
			})
			.catch((error) => {});
	};
	updateBidStatus = (lead_id, bid_status, assigned_by, index) => {
		axios
			.post(
				baseURL() + "/api/v1/leads/bid-status/",
				{
					lead_id: lead_id,
					bid_status: bid_status,
					assigned_by: assigned_by,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data);
			})
			.catch((error) => {});
	};
	updateServiceFee = (lead_id, service_fee, assigned_by, index) => {
		axios
			.post(
				baseURL() + "/api/v1/leads/service-fee/",
				{
					lead_id: lead_id,
					service_fee: service_fee,
					assigned_by: assigned_by,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data);
			})
			.catch((error) => {});
	};
	updateLeadStatus = (lead_id, lead_status, index) => {
		axios
			.post(
				baseURL() + "/api/v1/leads/update-lead-status/",
				{
					lead_id: lead_id,
					lead_status: lead_status,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data);
			})
			.catch((error) => {});
	};

	updateSoldStatus = (id, email, index, row) => {
		var lead = this.props.leads.find((x) => x._id == id);
		//Aassiging the lead
		// if (email != null && localStorage.getItem("username") != email) {
		//   let message =
		//     "You are about to re-assign this lead to yourself from " +
		//     email +
		//     ". Are you sure?";
		//   if (email == "") {
		//     message =
		//       "You are about to assign this lead to yourself?";
		//   }
		//   this.props.confirmAction(
		//     "Confirmation",
		//     message,
		//     () => {
		//       this.updateAssignee(localStorage.getItem("email"), id, index);
		//     },
		//     () => { }
		//   );

		// }

		// else {
		// Asked for permission
		// if (lead.sold_status) {
		// let message =
		//   "Are you sure you want to mark this lead as Un-Sold?";

		// this.props.confirmAction(
		//   "n/a",
		//   "n/a",
		//   () => {

		axios
			.post(
				baseURL() + "/api/v1/leads/update-sold-status/",
				{
					lead_id: row.original._id,
					star_by: localStorage.getItem("username"),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',

						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)

			.then((res) => {
				console.log("sold_status", res.data.data.sold_status);
				// this.props.allowToUpdate();
				// this.props.updateData(
				//   this.selectedLead._id,
				//   res.data.data,
				//   true,
				//   () => {}
				// );
			})

			.catch((error) => {});

		//   },
		//   () => { }
		// );

		//   }
		//   else {
		//     let message =
		//       "Are you sure you want to mark this lead as Sold? ";
		//     this.props.confirmAction(
		//       "Confirmation",
		//       message,
		//       () => {

		//         axios
		//           .post(
		//             baseURL() + "/api/v1/leads/update-sold-status/",
		//             {
		//               lead_id: row.original._id,
		//               star_by: localStorage.getItem("username"),
		//             },
		//             {
		//               headers: {
		//                 "x-access-token": localStorage.getItem("jwtToken"),
		//                 "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',

		//                 socket_id: localStorage.getItem("socket_id"),
		//               },
		//             }
		//           )

		//           .then((res) => {
		//             console.log("sold_status", res.data.data.sold_status);
		//             console.log("Lead star Updated step 2");
		//             this.props.allowToUpdate();
		//             this.props.updateData(
		//               this.selectedLead._id,
		//               res.data.data,
		//               true,
		//               () => { }
		//             );
		//           })

		//           .catch((error) => { }
		//           );

		//       },
		//       () => { }
		//     );

		//   }
		// }
	};
	updateLeadStar = (id, email, index, row) => {
		var lead = this.props.leads.find((x) => x._id == id);
		//Aassiging the lead
		// if (email != null && localStorage.getItem("username") != email) {
		//   let message =
		//     "You are about to re-assign this lead to yourself from " +
		//     email +
		//     ". Are you sure?";
		//   if (!email || email == "") {
		//     message = "You are about to assign this lead to yourself?";
		//   }
		//   this.props.confirmAction(
		//     "Confirmation",
		//     message,
		//     () => {
		//       this.updateAssignee(localStorage.getItem("email"), id, index);
		//     },
		//     () => {}
		//   );
		// } else {
		// Asked for permission
		if (lead.starred_by_user_ids) {
			// let message = "Are you sure you want to remove this lead from Hotlist?";
			// this.props.confirmAction(
			//   "Confirmation",
			//   message,
			//   () => {
			axios
				.post(
					baseURL() + "/api/v1/leads/update-starred-status/",
					{
						lead_id: row.original._id,
						star_by: localStorage.getItem("username"),
					},
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',

							socket_id: localStorage.getItem("socket_id"),
						},
					}
				)

				.then((res) => {
					// console.log(row);
					console.log("Lead star Updated step 2");
					// this.props.allowToUpdate();
					// this.props.updateData(
					//   this.selectedLead._id,
					//   res.data.data,
					//   true,
					//   () => {}
					// );
				})

				.catch((error) => {});
			//   },
			//   () => {}
			// );
		} else {
			// let message = "Are you sure you want to add this lead to Hotlist? ";
			// this.props.confirmAction(
			//   "Confirmation",
			//   message,
			//   () => {
			axios
				.post(
					baseURL() + "/api/v1/leads/update-starred-status/",
					{
						lead_id: row.original._id,
						star_by: localStorage.getItem("username"),
					},
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',

							socket_id: localStorage.getItem("socket_id"),
						},
					}
				)

				.then((res) => {
					// console.log(row);
					console.log("Lead star Updated step 2");
					// this.props.allowToUpdate();
					// this.props.updateData(
					//   this.selectedLead._id,
					//   res.data.data,
					//   true,
					//   () => {}
					// );
				})

				.catch((error) => {});
			//   },
			//   () => {}
			// );
		}
	};

	updateLeadArchive = (index, row) => {
		console.log("update lead Archive front 1");
		this.selectedLead = row.original;
		axios
			.post(
				baseURL() + "/api/v1/leads/archive-status/",
				{
					lead_id: this.selectedLead._id,
					archived_by: localStorage.getItem("username"),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				console.log("Lead Updated step 2");
				this.props.allowToUpdate();
				this.props.updateData(
					this.selectedLead._id,
					res.data.data,
					true,
					() => {}
				);
			})
			.catch((error) => {});
	};
	snoozeLead = (index, row, endDate, option) => {
		this.selectedLead = row.original;
		axios
			.post(
				baseURL() + "/api/v1/leads/snooze-lead/",
				{
					lead_id: this.selectedLead._id,
					snoozed_by: localStorage.getItem("username"),
					end_time: endDate,
					option: option,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.deleteLead(this.selectedLead._id);
				this.props.showAlert("Lead has been snoozed.");
			})
			.catch((error) => {});
	};
	searchLead = debounce((val) => {
		// Search locally for leads
		const localLeads = this.searchLeads(val);
		console.log("Local search result", localLeads);

		// Process local leads similarly to API response
		const processLeads = (leads) => {
			// Apply the same filtering or processing to the local leads as you would to API results
			const filteredLeads = secretUserLeadFilter(leads);
			if (filteredLeads.length === 0 && val.trim() !== "") {
				filteredLeads.length = 1; // Ensure at least one result
			}
			return filteredLeads;
		};

		// If no leads are found locally, proceed with API call
		if (localLeads.length === 0) {
			if (val && val.toString().trim().length > 0) {
				console.log("search api call");
				// Cancel any ongoing requests
				if (this.source) {
					this.source.cancel("Operation canceled due to new request.");
				}

				// Create a new CancelToken source
				this.source = axios.CancelToken.source();

				this.setState({ searchInProgress: true });

				axios
					.post(
						baseURL() + "/api/v1/leads/searchLead",
						{
							search_text: val,
						},
						{
							headers: {
								"x-access-token": localStorage.getItem("jwtToken"),
								"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
								socket_id: localStorage.getItem("socket_id"),
							},
							cancelToken: this.source.token,
						}
					)
					.then((response) => {
						const apiLeads = response.data.message;
						const filteredApiLeads = processLeads(apiLeads);
						this.setState({ searchInProgress: false });
						this.props.allowToUpdate();
						this.props.updateSearchData(filteredApiLeads, val, true, () => {});
					})
					.catch((error) => {
						if (axios.isCancel(error)) {
							console.log("Request canceled", error.message);
						} else {
							console.log("Search failed", error);
						}
						this.setState({ searchInProgress: false });
					});
			} else {
				console.log("Not Searchable", this.state.tempLeadsData.length);
				this.props.allowToUpdate();
				this.props.updateSearchData(
					this.state.tempLeadsData,
					val,
					true,
					() => {}
				);
			}
		} else {
			// Process local leads similarly to API results
			const filteredLocalLeads = processLeads(localLeads);
			this.props.updateSearchData(filteredLocalLeads, val, true, () => {});
		}
	}, 400);

	searchLeads = (searchText) => {
		console.log("Leads", this.props.leads);
		const leads = this.props.leads;
		// let matchedLeads = [];
		const regex = new RegExp(searchText, "i"); // Create a case-insensitive regular expression

		return leads
			.filter((lead) => {
				// Check all conditions
				return (
					(lead.name && regex.test(lead.name)) ||
					(lead.source && regex.test(lead.source)) ||
					(lead.channel && regex.test(lead.channel)) ||
					(lead.email && regex.test(lead.email)) ||
					(lead.address && regex.test(lead.address)) ||
					(lead.category &&
						lead.category.name &&
						regex.test(lead.category.name)) ||
					(lead.phone_number && regex.test(lead.phone_number)) ||
					(lead.stripped_phone_number &&
						regex.test(lead.stripped_phone_number)) ||
					(lead.svc_requested && regex.test(lead.svc_requested)) ||
					(lead.notes &&
						lead.notes.some(
							(noteObj) => noteObj.note && regex.test(noteObj.note)
						)) ||
					(lead.extra_links && regex.test(lead.extra_links))
				);
			})
			.sort((a, b) => b._id - a._id) // Sort by _id in descending order
			.slice(0, 100); // Limit to 100 results
	};
	// searchLead = debounce((val) => {
	// 	console.log("name @ searchLead: " + val);
	// 	if (val && val.toString().trim().length > 0) {
	// 		source && source.cancel("Operation canceled due to new request.");
	// 		// save the new request for cancellation
	// 		source = axios.CancelToken.source();

	// 		console.log("Do Search");
	// 		this.setState({ searchInProgress: true });
	// 		// set loader
	// 		axios
	// 			.post(
	// 				baseURL() + "/api/v1/leads/searchLead",
	// 				{
	// 					search_text: val,
	// 				},
	// 				{
	// 					headers: {
	// 						"x-access-token": localStorage.getItem("jwtToken"),
	// 						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
	// 						socket_id: localStorage.getItem("socket_id"),
	// 					},
	// 					cancelToken: source.token,
	// 				}
	// 			)
	// 			.then((res) => {
	// 				console.log("TL = " + res.data.message.length);
	// 				const filteredLeads = secretUserLeadFilter(
	// 					res.data.message
	// 				);
	// 				if (
	// 					filteredLeads &&
	// 					filteredLeads.length <= 0 &&
	// 					val.trim() != ""
	// 				) {
	// 					filteredLeads.length = 1;
	// 				}
	// 				this.setState({ searchInProgress: false });
	// 				this.props.allowToUpdate();
	// 				this.props.updateSearchData(
	// 					filteredLeads,
	// 					val,
	// 					true,
	// 					() => {}
	// 				);
	// 			})
	// 			.catch((error) => {
	// 				console.log("search failed");
	// 				this.setState({
	// 					isInProgress: false,
	// 				});
	// 			});
	// 	} else {
	// 		// if(val == ""){}
	// 		// console.log(this.state.tempLeadsData);
	// 		console.log("Not Searchable" + this.state.tempLeadsData.length);
	// 		this.props.allowToUpdate();
	// 		this.props.updateSearchData(
	// 			this.state.tempLeadsData,
	// 			val,
	// 			true,
	// 			() => {}
	// 		);
	// 	}
	// }, 400);

	getLeadCardDateFormat = (date) => {
		let d = new Date(date);
		d = calculateZoneSpecificTime(-6, d);
		return dateFormat(new Date(d), "mm/dd/yy h:MM TT");
	};
	onTabClick = (index) => {
		//
		if (index !== this.state.selectedTabIndex)
			this.setState({ selectedTabIndex: index });
	};

	oncolumnsdragged = (columns) => {
		axios
			.post(
				baseURL() + "/api/v1/users/updateUserWebViewColumnOrder",
				{
					email: localStorage.getItem("email"),
					columnsOrder: this.getNewOrderofColumns(columns),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				console.log("updateUserWebViewColumnOrder", res);
			})
			.catch((error) => {});
	};
	getNewOrderofColumns = (columns) => {
		var cols = [];
		for (var i = 0; i < columns.length; i++) {
			cols.push(columns[i].accessor);
		}
		return cols;
	};
	updateColumnSize = debounce((data) => {
		//
		//
		//

		axios
			.post(
				baseURL() + "/api/v1/users/updateUserWebViewColumnSize",
				{
					email: localStorage.getItem("email"),
					column: data[0].id,
					size: data[0].value,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {})
			.catch((error) => {});
	}, 2000);

	allowToUpdate = () => {};
	render() {
		let displayableColumns = [];
		this.firstColumns.forEach((element) => {
			if (
				element.accessor == "created_at" ||
				element.accessor == "actions" ||
				element.accessor == "bid_status" ||
				element.accessor == "channel" ||
				element.accessor == "reminder"
			) {
				displayableColumns.push(element);
			} else if (
				this.props.selectedColumns.find((e) => e == element.accessor) != null
			) {
				displayableColumns.push(element);
			}
		});
		// console.log("userrr", this.props.users)

		return (
			<React.Fragment>
				<div style={{ marginTop: 0, backgroundColor: "white" }}>
					{this.state.timeCompleted ? (
						<Suspense fallback={<div>Loading...</div>}>
							<div style={{ height: "100%", overflowY: "hidden" }}>
								{this.state.searchInProgress ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%",
											position: "absolute",
											backgroundColor: "#242933",
											zIndex: 9999,
										}}
									>
										<Loader
											type='Oval'
											color='#00BFFF'
											height={80}
											width={80}
										/>
									</div>
								) : (
									<ReactTableDraggableColumns
										// page={this.state.pageNum}
										draggableColumns={{
											onDraggedColumnChange: this.oncolumnsdragged,
											mode: "reorder",
											draggable: [
												// "snooze",
												"source",
												"created_at",
												"updated_at",
												"lead_number",
												"urgency",
												"archived",
												"starred_by_user_ids",
												"sold_status",
												"auto_review_count",
												"is_complete",
												"lead_status",
												"service_fee",
												"bid_status",
												"assigned_to",
												"address",
												"time_stamp",
												"name",
												"phone_number",
												"svc_requested",
												"notes",
												"extra_links",
												"email",
												"utc_time_stamp",
												"lop",
												"isLeadSpam",
											],
										}}
										className='-striped -highlight leadScreen .ReactTable .rt-tbody .rt-td'
										defaultSorted={[
											{
												id: "utc_time_stamp",
												desc: true,
											},
										]}
										PadRowComponent={<span>&emsp;&emsp;</span>}
										showPaginationTop={false}
										data={
											this.state.renderFilter
												? this.state.filteredLeadsData
												: this.props.leads
										}
										// data={this.state.sortedLeadData}
										// data={this.state.sortedLeadData ? this.state.sortedLeadData : this.props.leads}
										columns={displayableColumns}
										searching={true}
										sortable={false}
										minRows={0}
										resizable={false}
										filterable={true}
										PaginationComponent={Pagination}
										defaultFiltered={[
											{
												id: "archived",
												value: localStorage.getItem("archiveFilterValue"),
											},
											{
												id: "star",
												value: localStorage.getItem("starFilterValue"),
											},
											{
												id: "assigned_to",
												value: this.selectedAssignee,
											},
											{
												id: "utc_time_stamp",
												value: localStorage.getItem("timeFilterValue"),
											},
										]}
										noDataText='No record found'
										pageSize={this.state.pageSize}
										style={{
											height: this.props.tableHeight,
											display: "flex",
											justifyContent: "flex-start",
											// width: 500
										}}
										onPageChange={(pageIndex) => {
											this.setState({
												pageIndex: pageIndex,
											});
											// apply pagination when no search has applied
											// if (this.props.searchInputVal.trim().length <= 0) {
											if (pageIndex === parseInt(this.props.leads.length / 30))
												this.props.fetchMoreData(
													this.state.pageSize,
													pageIndex
												);
										}}
										renderTotalPagesCount={(pages) => {
											this.setState({
												TotalNumberOfPages: pages,
											});
											return pages;
										}}
										getTdProps={(state, rowInfo, column) => {
											return {
												onClick: (e) => {
													if (rowInfo != null) {
														this.selectedLead = rowInfo.original;
														this.selectedRowIndex = rowInfo.index;
													}
												},
											};
										}}
										getTrProps={(state, rowInfo, column, instance) => {
											if (rowInfo != null) {
												return {
													key: rowInfo.original._id,
													style: {
														display: "flex",
														// background: this.props.SelectedUser ===  rowInfo.original.assigned_to ? this.props.highlightColor : null,
														// background: this.props.getRowColorByAssignee(
														//   rowInfo.original.assigned_to
														// ),
														background:
															rowInfo.original && rowInfo.original.temp_color
																? rowInfo.original.temp_color
																: this.props.getRowColorByAssignee(
																		rowInfo.original.assigned_to
																  ),
														justifyContent: "center",
														alignItems: "center",
														height: 30,
														borderBottom:
															rowInfo.original.first && "2.7px dashed black",
														// width: 100
													},
													data: this.props.leads[rowInfo.index],
												};
											} else {
												return {};
											}
										}}
										getTbodyProps={(state, rowInfo, column, rtInstance) => {
											return {
												style: { overflowY: "overlay" },
											};
										}}
										onResizedChange={(data, data1) => {
											this.updateColumnSize(data);
										}}
										ref={(r) => (this.reactTable = r)}
									/>
								)}
							</div>
						</Suspense>
					) : null}

					{/* <div
						className="ExportElement"
						style={{
							// borderTop:'2px solid #f7f7f7',
							position: "relative",
							bottom: 30,
							left: "74%",
							height: "0",
							zIndex: 1,
							display:
								this.state.searchInProgress === false
									? "block"
									: "none",
						}}
						onClick={() => {
							this.onExportCustomersClick();
						}}
					>
						<button
							style={{
								width: 30,
								height: 20,
								backgroundImage: `url(${exportIcon})`,
							}}
						></button>
						<span
							style={{
								color: "#0A84FE",
								textTransform: "uppercase",
								fontSize: 12,
								fontFamily: "SF Pro Text Bold",
							}}
						>
							Export Customers
						</span>
					</div>
					<div
						style={{
							position: "absolute",
							bottom: 0,
							zIndex: 1,
							width: "100%",
						}}
					>
						{this.state.isExported ? (
							<ExportCustomers
								showAlert={this.props.showAlert}
								cancel={this.onExportCustomersCancelButtonClick}
							/>
						) : null}
					</div> */}
					{/* ma */}
					{this.state.selectedEmails?.length > 0 ? (
						<div
							style={{
								position: "absolute",
								bottom: 20,
								zIndex: 1,
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<MultipleEmailSms
								showAlert={this.props.showAlert}
								selectedEmails={this.state.selectedEmails}
								handleOpenFollowUpMode={this.handleOpenFollowUpMode}
								handleSetTitle={this.handleSetTitle}
								clearChecks={this.clearChecks}
								handelSelectedEmails={this.handelSelectedEmails}
								pageIndex={this.state.pageIndex}
							/>
						</div>
					) : null}

					{this.state.showAutoFollowUpModal && (
						<FollowUpModalNew
							title={this.state.multiEmailSmsTitle}
							message={"message"}
							leadName={"lead.name"}
							show={true}
							handleYes={this.autoFollowUpApiCallForBulk}
							// handleNo={onClose}
							autoSave={this.saveAutoMessage}
							showAlert={this.props.showAlert}
							sendReferralReview={this.sendReferral}
							handleCloseFollowUpMode={this.handleCloseFollowUpMode}
							autoFollowUpApiCallForBulk={this.autoFollowUpApiCallForBulk}
						/>
					)}

					{this.state.showStartSequenceModal && (
						<StartSequenceModal
							title='Select Sequence'
							show={this.state.showStartSequenceModal}
							yesFunc={() => {}}
							onHide={() => this.setState({ showStartSequenceModal: false })}
							rowId={this.state.rowId}
							email={this.state.email}
							showAlert={this.props.showAlert}
							state={this.state}
							UpdateLeadAfterAssigningSequence={
								this.props.UpdateLeadAfterAssigningSequence
							}
						/>
					)}
					{this.state.unPauseSequenceModal && (
						<StartSequenceModal
							title='Update Sequence Date'
							show={this.state.showStartSequenceModal}
							yesFunc={async () => {}}
							onHide={() =>
								this.setState({
									showStartSequenceModal: false,
									unPauseSequenceModal: false,
								})
							}
							rowId={this.state.rowId}
							email={this.state.email}
							showAlert={this.props.showAlert}
							state={this.state}
							UpdateLeadAfterAssigningSequence={
								this.props.UpdateLeadAfterAssigningSequence
							}
							unPause={true}
							unPauseId={this.state.unPauseId}
						/>
					)}
					{this.state.showPauseSequenceDetailsModal && (
						<PauseSequencDetailsModal
							title='Sequence Details'
							show={this.state.showPauseSequenceDetailsModal}
							showAlert={this.props.showAlert}
							sequenceId={this.state.sequenceId}
							showPauseButton={this.state.showPauseButton}
							selectedSequence={this.state.selectedSequence}
							totalTemplates={this.state.totalTemplates}
							startDate={this.state.startDate}
							nextTemplateNumber={this.state.nextTemplateNumber}
							leadId={this.state.leadId}
							state={this.state}
							email={this.state.email}
							firstTemplateDate={this.state.firstTemplateDate}
							secondTemplateDate={this.state.secondTemplateDate}
							thirdTemplateDate={this.state.thirdTemplateDate}
							dltFunc={async () => {
								const result = await unassignSequence(
									this.state.leadId,
									this.state.email
								);
								if (result.success) {
									if (this.state.email) {
										this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											result.data.updatedData
										);
									} else {
										this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											result.data.updatedData
										);
									}
									this.props.showAlert("Sequence Unassigned");
								} else this.props.showAlert("Operation failed");
							}}
							yesFunc={async () => {
								const index = this.state.sortedLeadData.findIndex(
									(obj) => obj._id === this.state.leadId
								);
								// console.log(this.state.sortedLeadData[index].sequenceStatus);
								const { status, data } = await startPauseSequence(
									this.state.leadId,
									{
										email: this.state.email,
									}
								);
								if (status === 200) {
									if (this.state.pause) {
										if (this.state.email) {
											// let object = this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatus: "2" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												// object[index]
												data.updatedData
											);
										} else {
											// let object = this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatusSms: "2" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
												// object[index]
											);
										}
										this.props.showAlert("Sequence Paused");
									} else {
										if (this.state.email) {
											// let object = this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatus: "1" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												// object[index]
												data.updatedData
											);
										} else {
											// let object = this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatusSms: "1" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
												// object[index]
											);
										}
										this.props.showAlert("Sequence Started");
									}
								} else {
									this.props.showAlert("Operation failed");
								}
							}}
							onHide={() =>
								this.setState({
									showPauseSequenceDetailsModal: false,
								})
							}
						/>
					)}
					{this.state.showPauseSequenceModal && (
						<SequenceConfirmation
							show={this.state.showPauseSequenceModal}
							onHide={() => {
								this.setState({
									showPauseSequenceModal: false,
								});
							}}
							title={
								this.state.pause
									? "Are you sure you want to pause the sequence?"
									: "Are you sure you want to start the sequence?"
							}
							description={
								this.state.pause
									? "After you pause the sequence there will be an option to continue the sequence"
									: "After you start the sequence there will be an option to pause the sequence"
							}
							yesFunc={async () => {
								// console.log(this.state.email);
								const index = this.state.sortedLeadData.findIndex(
									(obj) => obj._id === this.state.leadId
								);
								// console.log(this.state.sortedLeadData[index].sequenceStatus);
								const { status, data } = await startPauseSequence(
									this.state.leadId,
									{
										email: this.state.email,
									}
								);
								if (status === 200) {
									// console.log(data.updatedData);

									if (this.state.pause) {
										if (this.state.email) {
											// let object = await this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatus: "2" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												// object[index]
												data.updatedData
											);
										} else {
											// let object = await this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatusSms: "2" }
											//       : obj
											// );
											await this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												// object[index]
												data.updatedData
											);
										}
										this.props.showAlert("Sequence Paused");
									} else {
										if (this.state.email) {
											// this.setState({
											//   sortedLeadData: this.state.sortedLeadData.map(
											//     (obj, index1) =>
											//       index1 === index
											//         ? { ...obj, sequenceStatus: "1" }
											//         : obj
											//   ),
											// });
											///=======
											// let object = this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatus: "1" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
												// object[index]
											);
										} else {
											// let object = this.state.sortedLeadData.map(
											//   (obj, index1) =>
											//     index1 === index
											//       ? { ...obj, sequenceStatusSms: "1" }
											//       : obj
											// );
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
												// object[index]
											);
										}
										this.props.showAlert("Sequence Started");
									}
								} else {
									this.props.showAlert("Operation failed");
								}
							}}
							yesTitle={this.state.pause ? "Pause" : "Start"}
							showAlert={this.props.showAlert}
							state={this.state}
						/>
					)}
					{this.state.showPauseSequenceModalSms && (
						<SequenceConfirmation
							show={this.state.showPauseSequenceModalSms}
							onHide={() => {
								this.setState({
									showPauseSequenceModalSms: false,
								});
							}}
							title={
								this.state.pause
									? "Are you sure you want to pause the sequence?"
									: "Are you sure you want to start the sequence?"
							}
							description={
								this.state.pause
									? "After you pause the sequence there will be an option to continue the sequence"
									: "After you start the sequence there will be an option to pause the sequence"
							}
							yesFunc={async () => {
								const index = this.state.sortedLeadData.findIndex(
									(obj) => obj._id === this.state.leadId
								);
								// console.log(this.state.sortedLeadData[index].sequenceStatus);
								const { status, data } = await startPauseSequence(
									this.state.leadId,
									{
										email: false,
									}
								);
								if (status === 200) {
									if (this.state.pause) {
										// let object = await this.state.sortedLeadData.map(
										//   (obj, index1) =>
										//     index1 === index
										//       ? { ...obj, sequenceStatusSms: "2" }
										//       : obj
										// );
										await this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											data.updatedData
											//object[index]
										);
										this.props.showAlert("Sequence Paused");
									} else {
										// let object = this.state.sortedLeadData.map((obj, index1) =>
										//   index1 === index
										//     ? { ...obj, sequenceStatusSms: "1" }
										//     : obj
										// );
										this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											data.updatedData
											// object[index]
										);
										this.props.showAlert("Sequence Started");
									}
								} else {
									this.props.showAlert("Operation failed");
								}
							}}
							yesTitle={this.state.pause ? "Pause" : "Start"}
							showAlert={this.props.showAlert}
							state={this.state}
						/>
					)}
					{this.state.showUpdateDatePopup && (
						<SequenceConfirmation
							show={this.state.showUpdateDatePopup}
							onHide={() => {
								this.setState({ showUpdateDatePopup: false });
							}}
							title={"Update Sequence Date"}
							description={
								"The set sequence date has passed. Please update the sequence date to proceed."
							}
							yesFunc={async () => {
								this.setState({
									unPauseSequenceModal: true,
									showUpdateDatePopup: false,
								});
							}}
							yesTitle={"Update"}
							showAlert={this.props.showAlert}
							state={this.state}
						/>
					)}
					{this.state.reminderModal && (
						<ReminderModal
							show={this.state.reminderModal}
							leadId={this.state.rowId}
							leadName={this.state.leadName}
							UpdateLeadAfterAssigningSequence={
								this.props.UpdateLeadAfterAssigningSequence
							}
							reminderId={this.state.reminderId}
							reminderDate={this.state.reminderDate}
							reminderDescription={this.state.reminderDescription}
							showAlert={this.props.showAlert}
							update={this.state.updateReminder}
							onHide={() => this.setState({ reminderModal: false })}
							reminderAdmin={this.state.reminderAdmin}
							leadAssignedTo={this.state.leadAssignedTo}
							leads={this.props.leads}
							updatingAssigned={this.state.updatingAssigned}
							assignLead={() => {
								let message;
								if (
									!this.state.leadAssignedTo ||
									this.state.leadAssignedTo == ""
								) {
									message =
										"You are about to assign this lead to yourself. Are you sure?";
								} else {
									message =
										"You are about to re-assign this lead to yourself from " +
										this.state.leadAssignedTo +
										". Are you sure?";
								}
								this.props.confirmAction(
									"Confirmation",
									message,
									() => {
										this.setState({
											updatingAssigned: true,
										});
										this.updateAssignee(
											localStorage.getItem("email"),
											this.state.rowId,
											0
										);
									},
									() => {}
								);
							}}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function () {
	var date = new Date(this.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
	// January 4 is always in week 1.
	var week1 = new Date(date.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return (
		1 +
		Math.round(
			((date.getTime() - week1.getTime()) / 86400000 -
				3 +
				((week1.getDay() + 6) % 7)) /
				7
		)
	);
};

// Returns the four-digit year corresponding to the ISO week of the date.
// eslint-disable-next-line no-extend-native
Date.prototype.getWeekYear = function () {
	var date = new Date(this.getTime());
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
	return date.getFullYear();
};
export default LeadsScreen;

async function hasDatePassed(dateString1, dateString2) {
	if (dateString1 !== null && dateString1 !== "") {
		const date1 = new Date(
			`${dateString1[3]}${dateString1[4]}/${dateString1[0]}${dateString1[1]}/${dateString1[6]}${dateString1[7]}${dateString1[8]}${dateString1[9]}`
		);
		const date2 = new Date(dateString2);
		return date1 < date2;
	} else return true;
}

async function formatDate(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
}

async function unassignSequence(leadId, email) {
	const { status, data } = await removeSequence(leadId, {
		type: email,
	});
	if (status === 200) {
		return { success: true, data: data };
	} else {
		return { success: false, data: null };
	}
}
