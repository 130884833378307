import React, { useRef, useState, useEffect } from "react";
import linkifyHtml from "linkify-html"; // import ReactDOMServer from 'react-dom-server';
import moment from "moment";
import ".././Home/Home.css";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import Delete from "../assets/images/Delete.png";
import Edit from "../assets/images/Edit.png";
import call from "../assets/images/call.png";
import timelineRound from "../assets/images/timelineRound.png";
import CopyClip from "../assets/images/CopyClip.png";
import axios from "axios";
import {
	baseURL,
	capitalize,
	populateHtml,
	IsTextHTML,
} from "../Helpers/Constants";
import UserModal from "./UserModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const copy = require("clipboard-copy");
var colors = ["#000000", "#e60000", "#ff9900"];

var background = ["#000000", "#facccc", "#ffebcc"];
const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		["bold", "italic", "underline"],
		[{ color: colors }, { background: background }],
		[{ list: "ordered" }, { list: "bullet" }],
	],
};

function NoteModalMultiLine(props) {
	const Actions = {
		note: "Notes",
		starred: "Added to Hotlist",
		unstarred: "Removed from Hotlist",
		assigned: "Assignee Changed",
		sold: "Lead Sold",
		"un-sold": "Lead Un-Sold",
		"Lead received": "Lead Received",
		"un assigned": "Lead Un-Assigned",
		"canned email": "Canned Email Sent",
		"lead status": "Lead Status Updated",
		bid: "Bid Sent",
		"canned message": "Canned Message Sent",
		"review message": "Review Message Sent",
		"referral message": "Referral Message Sent",
		"reminder updated": "Reminder Updated",
		"reminder removed": "Reminder Removed",
		"reminder added": "Reminder Added",
		call: "Call",
		sms: "Message Sent",
	};
	const textInputRef = useRef(null);
	const {
		name,
		_id,
		email_auto_contact_count,
		auto_contact_count,
		auto_review_count,
		auto_referral_count,
	} = props.leadData.original;
	const [notesData, setNotesData] = useState("");
	const [notesArray, setNotesArray] = useState([]);
	const [noteContent, setNoteContent] = useState([]);
	const [loader, setLoader] = useState(false);
	const [showUpdateButton, setShowUpdateButton] = useState(false);
	const [leadData, setLeadData] = useState();
	const [updateActionId, setUpdateActionId] = useState(0);
	const [modalDisplay, setModalDisplay] = useState(false);
	const [delActionId, setDelActionId] = useState(0);
	const [userName, setUserName] = useState(localStorage.getItem("username"));
	const [timeLineFilterData, setTimeLineFilterData] = useState([]);
	const [showUserModal, setShowUserModal] = useState(false);
	const [receiverName, setReceiverName] = useState(null);

	// const emailHtml = null
	useEffect(() => {
		fetchLead(_id);
		// emailHtml = ReactDOMServer.renderToString(renderView);
	}, [props]);

	const handleSaveName = (name) => {
		setReceiverName(name);
	};
	const fetchLead = (id) => {
		setLoader(true);
		//
		axios
			.get(baseURL() + "/api/v1/leads/lead/" + id, {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					socket_id: localStorage.getItem("socket_id"),
				},
			})
			.then((res) => {
				setLoader(false);
				console.log("fetchLead", res?.data?.data);
				setLeadData(res?.data?.data);
				setTimeLineFilterData(
					res?.data?.data?.time_line.filter((item) => {
						return item.item_type !== "bid";
					})
				);
				setNotesArray(
					res?.data?.data?.time_line.filter((item) => {
						return item.item_type == "note";
					})
				);
				setNoteContent(res?.data?.data?.notes.reverse());
			})
			.catch((error) => {
				setLoader(false);
				props.showAlert("Failed to save changes, please try again");
			});
	};
	const handleCloseUserModal = () => {
		setShowUserModal(false);
	};
	const autoFollowUpApiCall = async (email) => {
		const html = populateHtml(
			timeLineFilterData,
			props.leadData.original,
			receiverName
		);
		console.log("data at share call", email);
		setLoader(true);
		// this.props.onPressContactLead();
		await axios
			.post(
				baseURL() + "/api/v1/leads/share-lead",
				{
					lead_id: _id,
					via: "share",
					user_email: email,
					message: html,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				console.log("response", res);
				setLoader(false);
				props.showAlert("Lead's details shared successfully.");
			})
			.catch((error) => {
				setLoader(false);
				props.showAlert("Failed to save changes, please try again");
			});
	};
	const handleShareButton = () => {
		setShowUserModal(true);
	};

	const handleUpdateNote = (action_id, val) => {
		setUpdateActionId(action_id);
		setShowUpdateButton(true);
		textInputRef.current.focus();
		setNotesData(val);
		// setEditorState(textInputRef.current)
		// setNoteInputState(val)
		// setNotesData(val)
		// updateNote(_id, action_id, notesData)
	};
	const updateNote = () => {
		setLoader(true);
		axios
			.post(
				baseURL() + "/api/v1/leads/updateNote",
				{
					lead_id: leadData._id,
					added_by: userName ? userName : "Web Portal",
					action_id: updateActionId,
					value: notesData,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				setNotesData("");
				setLoader(false);
				console.log("api_res", res);
				setTimeLineFilterData(
					res?.data?.data?.time_line.filter((item) => {
						return item.item_type !== "bid";
					})
				);
				setNotesArray(
					res?.data?.data?.time_line.filter((item) => {
						return item.item_type == "note";
					})
				);
				let inPlace = "notes";
				let temp = res?.data?.data;
				temp[inPlace] = res?.data?.data?.notes.reverse();
				setNoteContent(res?.data?.data?.notes.reverse());
				setShowUpdateButton(false);
				props.allowToUpdate();
				props.updateDataByID(leadData._id, temp);
			})
			.catch((error) => {
				setLoader(false);
				props.showAlert("Failed to save changes, please try again");
				setShowUpdateButton(false);
			});
	};

	const handleDeleteNote = (actionID) => {
		setDelActionId(actionID);
		setModalDisplay(true);
	};
	const deleteNote = () => {
		setModalDisplay(false);
		setLoader(true);
		axios
			.post(
				baseURL() + "/api/v1/leads/deleteNote",
				{
					lead_id: leadData._id,
					added_by: "Web Portal",
					action_id: delActionId,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				setLoader(false);
				console.log("api_res", res);
				setLeadData(res?.data?.data);
				setTimeLineFilterData(
					res?.data?.data?.time_line.filter((item) => {
						return item.item_type !== "bid";
					})
				);
				setNotesArray(
					res?.data?.data?.time_line.filter((item) => {
						return item.item_type == "note";
					})
				);
				let inPlace = "notes";
				let temp = res?.data?.data;
				temp[inPlace] = res?.data?.data?.notes.reverse();
				setNoteContent(res?.data?.data?.notes.reverse());
				props.allowToUpdate();
				props.updateDataByID(leadData._id, temp); // this.props.updateDataByID(leadID, res.data.data);
			})
			.catch((error) => {
				setLoader(false);
				props.showAlert("Failed to save changes, please try again");
			});
	};
	const handleCopyText = (str) => {
		if (str != "") {
			function listener(e) {
				e.clipboardData.setData("text/html", str);
				e.clipboardData.setData("text/plain", str);
				e.preventDefault();
			}
			document.addEventListener("copy", listener);
			document.execCommand("copy");
			document.removeEventListener("copy", listener);
			props.showAlert("Copied to clipboard");
		}
	};

	const handleSaveNote = () => {
		setLoader(true);
		props.submitValue(notesData);
		setNotesData("");
	};

	const handleInputChange = (event) => {
		setNotesData(event);
	};

	const convertToPlain = (rtf) => {
		rtf = rtf.replace(/\\par[d]?/g, "");
		console.log(
			"texttt",
			rtf
				.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")
				.trim()
		);
		return rtf
			.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")
			.trim();
	};

	return (
		<div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
			<Modal show={props.show} size='lg' onHide={props.onCancelEditModal}>
				<div>
					<Modal.Header>
						<Modal.Title className='modal-heading-cont'>
							<p className='modal-heading'>Notes</p>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ borderStyle: "none" }}>
						<div className='note-input-container'>
							<ReactQuill
								// styles={editorStyles}
								modules={modules}
								style={{ height: 150 }}
								className='ql-container ql-editor ql-toolbar'
								// theme="snow"
								value={notesData}
								onChange={handleInputChange}
								ref={textInputRef}
							/>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									marginTop: 10,
									width: "100%",
								}}
							>
								<button
									type='submit'
									disabled={loader || notesData == ""}
									className='note-modal-save-button'
									onClick={() => {
										showUpdateButton ? updateNote() : handleSaveNote(notesData);
									}}
								>
									{showUpdateButton ? "Update Note" : "Add Note"}
								</button>
							</div>
						</div>

						<span className='note-lead-name-container'>
							Action performed to{" "}
							<p
								style={{
									fontSize: 13,
									fontWeight: "600",
									paddingLeft: 5,
								}}
							>
								{name}
							</p>
						</span>
						<div className='note-divider'></div>
						<div className='note-info-container'>
							<div className='note-info-sub-container'>
								{" "}
								<img
									style={{
										cursor: "pointer",
										width: 14,
										height: 14,
									}}
									src={call}
								/>
								<span className='note-info-title'>Total Touch Points :</span>
								<p className='note-info-text-value'>
									{" "}
									{email_auto_contact_count +
										auto_contact_count +
										auto_review_count +
										auto_referral_count}
								</p>
							</div>
						</div>
						<div className='note-divider'></div>
						{!loader ? (
							<div className='notes-container'>
								{timeLineFilterData.map((item, index) => {
									const oldNote =
										noteContent[
											notesArray.findIndex((val) => {
												return val.created_at == item.created_at;
											})
										]?.note;
									const linkifiedContent = linkifyHtml(
										item.note ? item.note : oldNote ? oldNote : "n/a",
										{
											target: {
												url: "_blank",
												email: null,
											},
										}
									);
									const isTextHTML = IsTextHTML(linkifiedContent);

									return (
										<div
											key={item.created_at}
											style={{
												borderLeftColor: "#4256D0",
												borderLeftStyle: "solid",
												borderLeftWidth: 1,
												position: "relative",
												marginLeft: 8,
												backgroundColor: "#fafaff",
											}}
										>
											<div
												style={{
													marginLeft: 15,
													paddingLeft: 10,
													paddingBottom: 10,
													paddingTop: 20,
													paddingRight: 10,
													borderBottomColor: "lightgrey",
													borderBottomWidth: 1,
													borderBottomStyle: "solid",
												}}
											>
												<span
													style={{
														fontSize: 13,
														cursor: "default",
													}}
												>
													{index + 1}/{timeLineFilterData?.length}
												</span>
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													{" "}
													<p
														style={{
															fontSize: 15,
															fontWeight: "600",
															cursor: "default",
														}}
													>
														{" "}
														Action : {Actions[item.item_type]}
													</p>
													{index == 0 ? (
														<p
															style={{
																fontSize: 12,
																fontWeight: "600",
																paddingHorizontal: 4,
																backgroundColor: "red",
																color: "white",
																borderRadius: 15,
																width: 40,
																height: 17,
																textAlign: "center",
																marginLeft: 8,
															}}
														>
															{" "}
															New
														</p>
													) : null}
												</div>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														paddingTop: 13,
													}}
												>
													{item.item_type == "note" && isTextHTML ? (
														<p
															dangerouslySetInnerHTML={{
																__html: linkifiedContent,
															}}
															style={{
																fontSize: 13,
																cursor: "default",
															}}
														></p>
													) : item.item_type == "note" && !isTextHTML ? (
														<p
															style={{
																fontSize: 13,
																cursor: "default",
															}}
															className='display-linebreak'
														>
															{item.note
																? item.note
																: oldNote
																? oldNote
																: "n/a"}
														</p>
													) : null}
													<p
														style={{
															fontSize: 10,
															paddingTop: 10,
															paddingBottom: 5,
															cursor: "default",
														}}
													>
														{moment(item.created_at).format(
															"MM-DD-YYYY, h:mm a"
														)}
													</p>
												</div>
												{item.item_type == "note" ? (
													<div
														style={{
															position: "absolute",
															right: 35,
															top: 35,
															display: "flex",
															flexDirection: "row",
															width: 80,
															justifyContent: "space-between",
														}}
													>
														<button
															style={{
																borderStyle: "hidden",
																backgroundColor: "transparent",
															}}
															onClick={() =>
																handleCopyText(item.note ? item.note : oldNote)
															}
														>
															<img
																style={{
																	cursor: "pointer",
																	width: 25,
																	height: 25,
																}}
																src={CopyClip}
															/>
														</button>
														{item.action_id ? (
															<>
																<button
																	style={{
																		borderStyle: "hidden",
																		backgroundColor: "transparent",
																	}}
																	onClick={() =>
																		handleUpdateNote(item.action_id, item.note)
																	}
																>
																	<img
																		style={{
																			cursor: "pointer",
																			width: 25,
																			height: 25,
																		}}
																		src={Edit}
																	/>
																</button>
																<button
																	style={{
																		borderStyle: "hidden",
																		backgroundColor: "transparent",
																	}}
																	onClick={() =>
																		handleDeleteNote(item.action_id)
																	}
																>
																	<img
																		style={{
																			cursor: "pointer",
																			width: 25,
																			height: 25,
																		}}
																		src={Delete}
																	/>
																</button>
															</>
														) : null}
													</div>
												) : null}

												{/* <span style={{ fontSize: 10 }}>{item.item_type == "note" ? actionDate.toLocaleDateString() : item.created_at}</span> */}

												<img
													style={{
														height: 15,
														width: 15,
														left: -8,
														position: "absolute",
														top: "50%",
													}}
													src={timelineRound}
												/>
											</div>
										</div>
									);
								})}
							</div>
						) : (
							<div className='notes-loader'>
								<Loader
									type='Oval'
									color='gray'
									height={30}
									width={30}
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
									}}
								/>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<div className='modal-footer'>
							<button
								className='note-edit-modal-save-button'
								onClick={() => {
									handleShareButton();
								}}
							>
								Share
							</button>
							<button
								className='note-edit-modal-save-button'
								onClick={props.onCancelEditModal}
							>
								Close
							</button>
							<div style={{ width: 30 }}></div>
						</div>
					</Modal.Footer>
				</div>

				{/* ---------------Permission Modal------------------ */}

				<div>
					<Modal
						show={modalDisplay}
						style={{ top: "40%" }}
						size='sm'
						onHide={props.onCancelEditModal}
					>
						<Modal.Header>
							<Modal.Title
								style={{
									fontFamily: "Arial, Helvetica, sans-serif",
									fontWeight: "bold",
									fontSize: 20,
									width: "100%",
									textAlign: "center",
								}}
							>
								Delete Note
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div style={{ padding: 25 }}>
								<p style={{ fontSize: 15, fontWeight: "500" }}>
									Are you sure you want to delete this note?
								</p>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "row",
								}}
							>
								<button
									className='note-edit-modal-save-button'
									onClick={() => {
										deleteNote();
									}}
								>
									{" "}
									Yes
								</button>
								<button
									className='note-edit-modal-save-button'
									onClick={() => {
										setModalDisplay(false);
									}}
								>
									{" "}
									No
								</button>
							</div>
						</Modal.Footer>
					</Modal>
				</div>
			</Modal>
			{showUserModal && (
				<UserModal
					leadName={name}
					showUserModal={showUserModal}
					handleCloseUserModal={handleCloseUserModal}
					users={props.users}
					handleShareDetail={autoFollowUpApiCall}
					handleSaveName={handleSaveName}
				/>
			)}
		</div>
	);
}
export default NoteModalMultiLine;
